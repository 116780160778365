import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Trans} from "react-i18next";
import i18next, {t} from "i18next";
import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  ImageList,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {ModeOfTransportationDto} from "../../../features/modeOfTransportation/modeOfTransportation-api";
import {
  CommodityDto,
  EntityTypes,
  GroupedCharges,
  OrderGQLDto,
} from "../../../features/order/order-api";
import {useGetModeOftransportation} from "../hooks/UseGetModeOfTransportation";
import {ParcelShipmentDetailsProps} from "../pages/interfaces";
import ShipmentProcess from "./shipmentProcess.component";
import {
  getFormattedPrice,
  getFormattedPriceByInvoice,
} from "../../../../utils/formatCurrency.utils";
import Link from "../../../common/components/link";
import {AttachmentDto} from "../../../features/attachment/attachment-api";
import {ImageWithModal} from "../../../common/components/imageWithModal";
import {
  getCustomValue,
  groupChargesByName,
  stageColor,
} from "../../../../utils/helper.utils";
import {boxStyle} from "../../../Delivery/components/totalCost.component";
import {getInvoiceStatusColors} from "../../../Invoices/AllInvoices/components/invoiceCard";
import {getLangCodeFromI18n} from "../../../app/app.component";
import PrintLabel from "./printLabel.component";
import {useAppSelector} from "../../../store";
import {AccountingTransactionDto} from "../../../features/accountingTransaction/accountingTransaction-api";
import {ContactAddressDto} from "../../../features/contactAddress/contactAddress-api";
import {getConsigneeLocation} from "../../AllParcelShipments/utils/parcelShipmentTable.utils";
import {Field, Form, Formik} from "formik";
import EditIcon from "@mui/icons-material/Edit";
import {LoadingButton} from "@mui/lab";
import {useUpdateCustomerNote} from "../hooks/useUpdateCustomerNote";
import {toastError} from "../../../common/utils/toastMessages";
import {toast} from "react-hot-toast";
import {TextField} from "formik-mui";
import {useSearchParams} from "react-router-dom";

export default function ParcelShipmentDetails({
  parcelShipment,
  loadParcelShipment,
}: ParcelShipmentDetailsProps) {
  const lang = getLangCodeFromI18n();

  const config = useAppSelector(
    (state: any) => state.organizationConfigState.modules.parcelTracking,
  );

  const deliveryConfig = useAppSelector(
    (state: any) => state.organizationConfigState.modules.delivery,
  );

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [consigneeAddress, setConsigneeAddress] = useState<string[]>([]);
  const [commodities, setCommodities] = useState<CommodityDto[]>([]);
  const [orderAttachments, setOrderAttachments] = useState<AttachmentDto[]>([]);
  const [groupedCharges, setGroupedCharges] = useState<GroupedCharges[]>([]);
  const purchases =
    parcelShipment?.shipments?.filter((x) => x.orderType == "Purchase") ?? [];

  const enableCustomerNotes = deliveryConfig?.enableCustomerNotes === true;
  const [searchParams, setSearchParams] = useSearchParams();
  const isNoteEditFromQuery = searchParams?.get("editNote") === "1";

  const {updateCustomerNote, isNoteEditAllowed} = useUpdateCustomerNote();
  const [isNoteEditMode, setIsNoteEditMode] = useState(false);

  type CustomerNoteFormValues = {
    customer_notes: string | null | undefined;
  };

  const handleEditNoteSubmit = useCallback(
    async (values: CustomerNoteFormValues) => {
      if (parcelShipment?.orderId) {
        try {
          const response: any = await updateCustomerNote(
            parcelShipment?.orderId,
            values?.customer_notes?.trim() ?? "",
          );
          if (response.error) {
            toastError(response);
          } else {
            if (loadParcelShipment) await loadParcelShipment();
            toast.success(
              t("parcelShipments.shipmentDetails.notesEditSuccess"),
            );
            setIsNoteEditMode(false);
          }
        } catch (error) {
          toastError(error);
        }
      }
    },
    [parcelShipment, updateCustomerNote, loadParcelShipment],
  );

  const customerNote = useMemo(
    () => getCustomValue(parcelShipment?.customValues, "customer_notes"),
    [parcelShipment, getCustomValue],
  );

  const noteEditFormRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      isNoteEditFromQuery &&
      isNoteEditAllowed(parcelShipment?.orderStatus?.orderStatusName) &&
      noteEditFormRef?.current
    ) {
      setIsNoteEditMode(true);
      setSearchParams(
        new URLSearchParams({
          ...searchParams,
          editNote: "0",
        }),
      );
      noteEditFormRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [isNoteEditFromQuery, noteEditFormRef, isNoteEditMode, isNoteEditAllowed]);

  useEffect(() => {
    const contactAddress = parcelShipment.orderEntities?.find(
      (x) => x.entityType == "Consignee",
    )?.contactAddress;

    getConsigneeLocation(contactAddress as ContactAddressDto, lang, t).then(
      (result) => {
        const lines = [
          ...result.filter(
            (value) => value && value.length && value.length > 0,
          ),
        ];
        setConsigneeAddress(lines);
      },
    );

    const commodities: CommodityDto[] = [];
    parcelShipment.commodities?.forEach((el) => {
      el.commodity && commodities.push(el.commodity);
    });
    setCommodities(commodities);

    const attachments: AttachmentDto[] = [];
    parcelShipment.attachments?.forEach((s) => attachments?.push(s));
    parcelShipment.shipments?.forEach((x) => {
      x.attachments && x.attachments.forEach((s) => attachments?.push(s));
    });
    setOrderAttachments(attachments ?? []);

    const groups = groupChargesByName(parcelShipment.charges || [], lang);
    setGroupedCharges(groups);
  }, [parcelShipment, lang]);

  const modeOfTransportation: ModeOfTransportationDto | undefined =
    useGetModeOftransportation(
      parcelShipment?.customValues?.find(
        (x) => x.key == "modeOfTransportationId",
      )?.value,
    );

  const [expandItems, setExpandItems] = useState<boolean>(true);
  const [expandPhotos, setExpandPhotos] = useState<boolean>(true);
  const [expandCost, setExpandCost] = useState<boolean>(true);

  const imagesList = useRef<any>(null);

  const orderIds = [];
  if (parcelShipment?.orderId && parcelShipment?.orderId > 0) {
    orderIds.push(parcelShipment.orderId);
  }

  const totalWeight =
    parcelShipment?.commodities?.length &&
    parcelShipment.commodities.reduce(
      (sum, a) => (sum += a?.commodity?.weightTotal || 0),
      0,
    );

  const totalCost = groupedCharges.reduce(
    (sum, a) => (sum += a.totalAmount),
    0,
  );

  const weightUnit =
    parcelShipment?.commodities?.[0]?.commodity?.weightUnit ?? "";

  const customerDeclaredWeight = Number(
    getCustomValue(
      parcelShipment?.commodities?.[0]?.commodity?.customValues,
      "customer_declared_weight",
    ),
  );
  const customerDeclaredWeightUnit = getCustomValue(
    parcelShipment?.commodities?.[0]?.commodity?.customValues,
    "customer_declared_weight_unit",
  );

  const showCustomerDeclaredWeight =
    (customerDeclaredWeight &&
      totalWeight &&
      customerDeclaredWeight !== totalWeight) ||
    (customerDeclaredWeightUnit &&
      weightUnit &&
      customerDeclaredWeightUnit !== weightUnit);

  const getTotalDeclaredValue = (commoditiesData: CommodityDto[]) => {
    let sum = 0;
    commoditiesData.forEach((commodities: any) => {
      sum += commodities.containerCommodities?.reduce(
        (acc: number, cur: CommodityDto) =>
          acc + (cur?.unitaryValue ?? 0) * (cur?.quantity ?? 0),
        0,
      );
      return sum;
    });
    return sum;
  };

  const getLabelValue = (purchase: OrderGQLDto) => {
    const orderStatus = purchase?.orderStatus;
    return orderStatus?.customValues &&
      `portal_name_${lang}` in orderStatus.customValues
      ? orderStatus.customValues[`portal_name_${lang}`]
      : orderStatus?.orderStatusName ?? "";
  };

  const renderCharge = (chargeName: any, price: any, testId?: string) => {
    return (
      <>
        <Grid container direction="row" justifyContent="space-between" mt={1}>
          <Typography variant="body1">{chargeName}</Typography>
          <Grid
            borderBottom={`1px dotted ${theme.palette.info.border}`}
            flexGrow={1}
            height="20px"
          />
          <Typography data-testid={testId} variant="body4">
            {price}
          </Typography>
        </Grid>
      </>
    );
  };

  const getFirstOpenInvoice = useCallback(
    () =>
      parcelShipment.invoices?.find(
        (x) => x.accountingTransactionStatus === "Open",
      ),
    [parcelShipment.invoices],
  );

  const getInvoiceLink = (invoice?: AccountingTransactionDto) => {
    if (
      invoice &&
      invoice.transactionNumber &&
      invoice.accountingTransactionId
    ) {
      return `../invoices/${invoice.accountingTransactionId}/tracking/${invoice.transactionNumber}`;
    }
  };

  return (
    <>
      <Grid container columns={12}>
        <Grid xs={12} md={6} lg={5}>
          {isDesktop && (
            <Grid xs={12} md={12} lg={12}>
              <Grid
                container
                mb={3}
                mt={4}
                gap={1}
                alignItems="baseline"
                justifyContent="space-between"
              >
                <Grid>
                  <Typography variant="h2" style={{flex: 1}}>
                    <Trans i18nKey="parcelShipments.shipmentDetails.shipmentTracking">
                      Parcel tracking
                    </Trans>
                    <br />
                  </Typography>
                  <Typography variant="h1" style={{flex: 1}}>
                    #&nbsp;
                    <span data-testid="shipment-tracking-number">
                      {parcelShipment?.trackingNumber}
                    </span>
                  </Typography>
                </Grid>
                <Chip
                  data-testid="shipment-status"
                  style={{
                    ...stageColor(
                      parcelShipment?.orderStatus?.customValues &&
                        "portal_stage_color" in
                          parcelShipment.orderStatus.customValues
                        ? parcelShipment?.orderStatus?.customValues[
                            "portal_stage_color"
                          ]
                        : undefined,
                    ),
                    fontSize: "16px",
                  }}
                  label={
                    parcelShipment?.orderStatus?.customValues &&
                    `portal_name_${lang}` in
                      parcelShipment.orderStatus.customValues
                      ? parcelShipment?.orderStatus?.customValues[
                          `portal_name_${lang}`
                        ]
                      : parcelShipment?.orderStatus?.orderStatusName
                  }
                />
              </Grid>
            </Grid>
          )}
          <Box sx={isDesktop ? boxStyle : {marginTop: 2}}>
            <Grid container direction={"column"}>
              {!isDesktop && (
                <Grid xs={12}>
                  <Grid
                    container
                    alignItems="baseline"
                    mb={3}
                    display={"flex"}
                    flexDirection={"row"}
                    gap={1}
                  >
                    <Grid display={"flex"} flexWrap={"wrap"}>
                      <Typography
                        component={"span"}
                        variant="body4"
                        style={{flex: 1}}
                        pr={1}
                      >
                        <Trans i18nKey="parcelShipments.shipmentDetails.expectedShipment">
                          Parcel
                        </Trans>
                      </Typography>
                      <Typography
                        component={"span"}
                        variant="body3"
                        color={"primary"}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                        maxWidth={"100vw"}
                      >
                        #&nbsp;{parcelShipment?.trackingNumber}
                      </Typography>
                    </Grid>
                    <Grid marginLeft={"auto"}>
                      <Chip
                        data-testid="shipment-status"
                        style={{
                          ...stageColor(
                            parcelShipment?.orderStatus?.customValues &&
                              "portal_stage_color" in
                                parcelShipment.orderStatus.customValues
                              ? parcelShipment?.orderStatus?.customValues[
                                  "portal_stage_color"
                                ]
                              : undefined,
                          ),
                          height: "24px",
                          fontSize: "16px",
                        }}
                        label={
                          parcelShipment?.orderStatus?.customValues &&
                          `portal_name_${lang}` in
                            parcelShipment.orderStatus.customValues
                            ? parcelShipment?.orderStatus?.customValues[
                                `portal_name_${lang}`
                              ]
                            : parcelShipment?.orderStatus?.orderStatusName
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{xs: 1, sm: 2, md: 3}}
              >
                <Grid
                  xs={12}
                  sx={{
                    display: "flex",
                    padding: 0,
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      padding: 0,
                      [theme.breakpoints.down("sm")]: {
                        flexWrap: "wrap",
                      },
                      [theme.breakpoints.up("sm")]: {
                        flexWrap: "nowrap",
                      },
                    }}
                  >
                    <Grid sx={{width: 175, minWidth: 175}}>
                      <Typography variant="body1">
                        <Trans
                          i18nKey={"parcelShipments.shipmentDetails.recipient"}
                        >
                          Recipient:
                        </Trans>
                        &nbsp;
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography variant="body4" data-testid="recipient-name">
                        {`${
                          parcelShipment?.orderEntities?.find(
                            (entity) =>
                              entity.entityType === EntityTypes.Consignee,
                          )?.contact?.name || ""
                        }`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sx={{
                    display: "flex",
                    padding: 0,
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      padding: 0,
                      [theme.breakpoints.down("sm")]: {
                        flexWrap: "wrap",
                      },
                      [theme.breakpoints.up("sm")]: {
                        flexWrap: "nowrap",
                      },
                    }}
                  >
                    <Grid sx={{width: 175, minWidth: 175}}>
                      <Typography variant="body1">
                        <Trans i18nKey={"parcelShipments.shipmentDetails.to"}>
                          To:
                        </Trans>
                        &nbsp;
                      </Typography>
                    </Grid>
                    <Grid>
                      {consigneeAddress.map((line) => {
                        return (
                          <>
                            <Typography
                              variant={"body4"}
                              data-testid="consignee-address"
                            >
                              {line}
                            </Typography>
                            <br />
                          </>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sx={{
                    display: "flex",
                    padding: 0,
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      padding: 0,
                      [theme.breakpoints.down("sm")]: {
                        flexWrap: "wrap",
                      },
                      [theme.breakpoints.up("sm")]: {
                        flexWrap: "nowrap",
                      },
                    }}
                  >
                    <Grid sx={{width: 175, minWidth: 175}}>
                      <Typography variant="body1">
                        <Trans
                          i18nKey={"parcelShipments.shipmentDetails.service"}
                        >
                          Service:
                        </Trans>
                        &nbsp;
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography variant={"body4"} data-testid="service">
                        {modeOfTransportation?.customValues &&
                        modeOfTransportation?.customValues[
                          "service_description_" + i18next.language
                        ]?.length > 0
                          ? modeOfTransportation?.customValues[
                              "service_description_" + i18next.language
                            ]
                          : modeOfTransportation?.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sx={{
                    display: "flex",
                    padding: 0,
                    flexDirection: "column",
                  }}
                >
                  {showCustomerDeclaredWeight ? (
                    <>
                      <Grid
                        sx={{
                          display: "flex",
                          padding: 0,
                          [theme.breakpoints.down("sm")]: {
                            flexWrap: "wrap",
                          },
                          [theme.breakpoints.up("sm")]: {
                            flexWrap: "nowrap",
                          },
                        }}
                      >
                        <Grid sx={{width: 175, minWidth: 175}}>
                          <Typography variant="body1">
                            <Trans
                              i18nKey={
                                "parcelShipments.shipmentDetails.declaredWeight"
                              }
                            >
                              Declared Weight:
                            </Trans>
                            &nbsp;
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography
                            variant={"body4"}
                            data-testid="declared-weight"
                          >
                            {` ${customerDeclaredWeight} ${
                              customerDeclaredWeightUnit
                                ? t(customerDeclaredWeightUnit.toLowerCase())
                                : ""
                            }`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          padding: 0,
                          [theme.breakpoints.down("sm")]: {
                            flexWrap: "wrap",
                          },
                          [theme.breakpoints.up("sm")]: {
                            flexWrap: "nowrap",
                          },
                        }}
                      >
                        <Grid sx={{width: 175, minWidth: 175}}>
                          <Typography variant="body1">
                            <Trans
                              i18nKey={
                                "parcelShipments.shipmentDetails.actualWeight"
                              }
                            >
                              Actual Weight:
                            </Trans>
                            &nbsp;
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography
                            variant={"body4"}
                            data-testid="actual-weight"
                          >
                            {` ${totalWeight} ${
                              weightUnit ? t(weightUnit.toLowerCase()) : ""
                            }`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Grid
                      sx={{
                        display: "flex",
                        padding: 0,
                        [theme.breakpoints.down("sm")]: {
                          flexWrap: "wrap",
                        },
                        [theme.breakpoints.up("sm")]: {
                          flexWrap: "nowrap",
                        },
                      }}
                    >
                      <Grid sx={{width: 175, minWidth: 175}}>
                        <Typography variant="body1">
                          <Trans
                            i18nKey={"parcelShipments.shipmentDetails.weight"}
                          >
                            Weight:
                          </Trans>
                          &nbsp;
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography variant={"body4"} data-testid="weight">
                          {totalWeight
                            ? ` ${totalWeight} ${
                                weightUnit ? t(weightUnit.toLowerCase()) : ""
                              }`
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Divider
              sx={{
                backgroundColor: "#D8E6FB",
                my: 4,
              }}
            />

            <Grid>
              <Box>
                <Box
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  onClick={() => setExpandItems((prev) => !prev)}
                  sx={{cursor: "pointer"}}
                >
                  <Grid md display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      variant={isDesktop ? "h2" : "h3"}
                      sx={{fontSize: "18px !important"}}
                    >
                      <Trans
                        data-testid="shipment-items-in-parcel"
                        i18nKey={
                          "parcelShipments.shipmentDetails.itemsInParcel"
                        }
                      >
                        Items in the parcel
                      </Trans>
                    </Typography>
                    &nbsp;
                    <Typography color={"#3E80FF"} textAlign={"right"}>
                      {expandItems ? (
                        <Trans
                          data-testid="shipment-hide-all-items-in-parcel"
                          i18nKey={"parcelShipments.shipmentCard.hideAll"}
                        >
                          Hide all
                        </Trans>
                      ) : (
                        <Trans
                          data-testid="shipment-show-all-items-in-parcel"
                          i18nKey={"parcelShipments.shipmentCard.showAll"}
                        >
                          Show all
                        </Trans>
                      )}
                      &nbsp;({commodities?.length})
                    </Typography>
                  </Grid>
                </Box>
                <Grid mt={1}>
                  <Collapse in={expandItems} timeout="auto">
                    {config?.showParcelCommodities == true &&
                      commodities?.length &&
                      commodities.map((container) => {
                        return (
                          <>
                            {container.containerCommodities?.map(
                              (commodity, commodityIndex) => (
                                <Grid
                                  key={commodity?.commodityId}
                                  mt={1}
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  data-testid={`commodity-${commodityIndex}`}
                                >
                                  <Typography
                                    variant="caption1"
                                    sx={{
                                      width: "max-content",
                                      flexBasis: "70%",
                                      fontSize: "16px",
                                    }}
                                    textOverflow={"ellipsis"}
                                    data-testid={`commodity-description`}
                                  >
                                    {`${commodity?.description || ""}`}
                                  </Typography>
                                  <Typography
                                    data-testid={`commodity-quantity`}
                                    variant="caption1"
                                    display={"flex"}
                                    justifyContent={"end"}
                                    ml={1}
                                    sx={{
                                      width: "max-content",
                                      flexBasis: "15%",
                                      fontSize: "16px",
                                    }}
                                  >
                                    <span>
                                      {commodity?.quantity}&nbsp;{t("pieces")}
                                    </span>
                                  </Typography>
                                  <Typography
                                    variant="caption1"
                                    display={"flex"}
                                    justifyContent={"end"}
                                    sx={{
                                      width: "max-content",
                                      flexBasis: "18%",
                                      fontSize: "16px",
                                    }}
                                  >
                                    <span
                                      data-testid={`commodity-price-per-item`}
                                    >
                                      {getFormattedPrice(
                                        commodity?.unitaryValue,
                                      )}
                                    </span>
                                  </Typography>
                                </Grid>
                              ),
                            )}
                          </>
                        );
                      })}
                    {config?.showParcelCommodities == false &&
                      purchases.length > 0 &&
                      purchases?.map((purchase, index) => {
                        const container =
                          purchase.commodities?.length &&
                          purchase.commodities[0].commodity
                            ? purchase.commodities[0].commodity
                            : {};
                        return (
                          <>
                            <Grid
                              md
                              display={"flex"}
                              justifyContent={"space-between"}
                              my={3}
                              flexWrap={"wrap"}
                              gap={1}
                            >
                              <Grid
                                display={"flex"}
                                flexWrap={"wrap"}
                                width={"max-content"}
                              >
                                <Typography
                                  variant={isDesktop ? "h2" : "h3"}
                                  display={"inline-block"}
                                  sx={{
                                    fontSize: "16px !important",
                                    fontFamily: "Inter Regular",
                                  }}
                                >
                                  <Trans
                                    data-testid={`shipment-purchase-${index}`}
                                    i18nKey={"purchaseTrack"}
                                  >
                                    Purchase track:
                                  </Trans>
                                </Typography>
                                &nbsp;
                                <Typography
                                  variant={isDesktop ? "h2" : "h3"}
                                  color="primary"
                                  display={"inline-block"}
                                  textOverflow={"ellipsis"}
                                  overflow={"hidden"}
                                  whiteSpace={"nowrap"}
                                  maxWidth={"100vw"}
                                  sx={{
                                    fontSize: "16px !important",
                                    fontFamily: "Inter Regular",
                                  }}
                                >
                                  {purchase?.trackingNumber?.toUpperCase()}
                                </Typography>
                              </Grid>

                              <Grid
                                display={"flex"}
                                justifyContent={"end"}
                                marginLeft={"auto"}
                              >
                                <Chip
                                  label={getLabelValue(purchase)}
                                  variant="outlined"
                                  style={{
                                    minHeight: "24px",
                                    height: "24px",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    border: 0,
                                    padding: "0 6px",
                                    backgroundColor: "#E3EEFF",
                                    width: "max-content",
                                  }}
                                  color={"primary"}
                                />
                              </Grid>
                            </Grid>
                            {container.containerCommodities?.map(
                              (commodity, commodityIndex) => {
                                return (
                                  <Grid
                                    key={commodity?.commodityId}
                                    mt={1}
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    data-testid={`commodity-${commodityIndex}`}
                                  >
                                    <Typography
                                      variant="caption1"
                                      sx={{
                                        width: "max-content",
                                        flexBasis: "70%",
                                        fontSize: "16px",
                                      }}
                                      textOverflow={"ellipsis"}
                                      data-testid={`commodity-description`}
                                    >
                                      {`${commodity?.description || ""}`}
                                    </Typography>
                                    <Typography
                                      data-testid={`commodity-quantity`}
                                      variant="caption1"
                                      display={"flex"}
                                      justifyContent={"end"}
                                      ml={1}
                                      sx={{
                                        width: "max-content",
                                        flexBasis: "15%",
                                        fontSize: "16px",
                                      }}
                                    >
                                      <span>
                                        {commodity?.quantity}&nbsp;{t("pieces")}
                                      </span>
                                    </Typography>
                                    <Typography
                                      variant="caption1"
                                      display={"flex"}
                                      justifyContent={"end"}
                                      sx={{
                                        width: "max-content",
                                        flexBasis: "18%",
                                        fontSize: "16px",
                                      }}
                                    >
                                      <span
                                        data-testid={`commodity-price-per-item`}
                                      >
                                        {getFormattedPrice(
                                          commodity?.unitaryValue,
                                        )}
                                      </span>
                                    </Typography>
                                  </Grid>
                                );
                              },
                            )}
                          </>
                        );
                      })}
                    <Grid
                      container
                      width={1}
                      mt={3}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography variant={"body3"} maxWidth={"100vw"}>
                        <Trans i18nKey={"declaredValue"}>Declared value</Trans>:
                      </Typography>
                      <Grid
                        borderBottom="1px dotted #D8E6FB"
                        flexGrow={1}
                        height="14px"
                        mx={1}
                      />
                      <Typography
                        variant={"body3"}
                        maxWidth={"100vw"}
                        data-testid="declared-value-total-cost"
                      >
                        {commodities &&
                          getFormattedPrice(getTotalDeclaredValue(commodities))}
                      </Typography>
                    </Grid>
                  </Collapse>
                </Grid>
              </Box>
            </Grid>

            {/* Customer Notes */}
            {parcelShipment && enableCustomerNotes ? (
              <>
                <Divider
                  sx={{
                    backgroundColor: "#D8E6FB",
                    my: 4,
                  }}
                />

                <Grid ref={noteEditFormRef}>
                  <Box>
                    <Grid display={"flex"} justifyContent={"space-between"}>
                      <Typography variant={isDesktop ? "h2" : "h3"} mb={2}>
                        <Trans
                          i18nKey={
                            "parcelShipments.shipmentDetails.customerNotes"
                          }
                        >
                          Notes
                        </Trans>
                      </Typography>
                      {!isNoteEditMode &&
                        isNoteEditAllowed(
                          parcelShipment?.orderStatus?.orderStatusName,
                        ) && (
                          <EditIcon
                            sx={{color: "#6C757D", cursor: "pointer"}}
                            onClick={() => setIsNoteEditMode(true)}
                          />
                        )}
                    </Grid>
                    {isNoteEditMode ? (
                      <Formik
                        initialValues={
                          {
                            customer_notes: customerNote,
                          } as CustomerNoteFormValues
                        }
                        onSubmit={handleEditNoteSubmit}
                      >
                        {({isSubmitting}) => {
                          return (
                            <Form>
                              <Grid mb={2}>
                                <Field
                                  name={"customer_notes"}
                                  component={TextField}
                                  placeholder={t(
                                    "parcelShipments.shipmentDetails.notesPlaceholder",
                                  )}
                                  multiline
                                  minRows={2}
                                  maxRows={8}
                                  sx={{
                                    width: "100%",
                                    "& .MuiInputBase-root": {
                                      padding: 0,
                                      overflow: "hidden",
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid
                                display={"flex"}
                                gap={2}
                                flexDirection={{
                                  sm: "row",
                                  xs: "column-reverse",
                                }}
                              >
                                <Button
                                  disabled={isSubmitting}
                                  variant={"outlined"}
                                  onClick={() => setIsNoteEditMode(false)}
                                  fullWidth
                                >
                                  <Trans
                                    i18nKey={
                                      "parcelShipments.shipmentDetails.notesCancel"
                                    }
                                  >
                                    Cancel
                                  </Trans>
                                </Button>
                                <LoadingButton
                                  loading={isSubmitting}
                                  type={"submit"}
                                  variant={"contained"}
                                  color={"secondary"}
                                  fullWidth
                                >
                                  <Trans
                                    i18nKey={
                                      "parcelShipments.shipmentDetails.notesSave"
                                    }
                                  >
                                    Save
                                  </Trans>
                                </LoadingButton>
                              </Grid>
                            </Form>
                          );
                        }}
                      </Formik>
                    ) : (
                      <Grid>
                        <Typography
                          variant={"body4"}
                          sx={{overflowWrap: "anywhere"}}
                        >
                          {customerNote ? (
                            customerNote
                          ) : isNoteEditAllowed(
                              parcelShipment?.orderStatus?.orderStatusName,
                            ) ? (
                            <Button
                              variant={"link"}
                              onClick={() => setIsNoteEditMode(true)}
                            >
                              <Trans
                                i18nKey={
                                  "parcelShipments.shipmentDetails.noteLink"
                                }
                              >
                                Add note for packer
                              </Trans>
                            </Button>
                          ) : (
                            ""
                          )}
                        </Typography>
                      </Grid>
                    )}
                  </Box>
                </Grid>
              </>
            ) : null}

            {/* Photos */}
            {orderAttachments &&
            orderAttachments.length &&
            orderAttachments.length > 0 ? (
              <>
                <Divider
                  sx={{
                    backgroundColor: "#D8E6FB",
                    my: 4,
                  }}
                />

                <Grid>
                  <Box>
                    <Box
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      onClick={() => setExpandPhotos((prev) => !prev)}
                      sx={{cursor: "pointer"}}
                    >
                      <Grid
                        md
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        <Typography variant={isDesktop ? "h2" : "h3"}>
                          <Trans
                            i18nKey={
                              "parcelShipments.shipmentDetails.photosGoods"
                            }
                          >
                            Photos of the goods
                          </Trans>
                        </Typography>
                        &nbsp;
                        {orderAttachments.length > 5 ? (
                          <Typography color={"#3E80FF"} textAlign={"right"}>
                            {expandPhotos ? (
                              <Trans
                                data-testid="shipment-hide-all-photos"
                                i18nKey={"parcelShipments.shipmentCard.hideAll"}
                              >
                                Hide all
                              </Trans>
                            ) : (
                              <Trans
                                data-testid="shipment-show-all-photos"
                                i18nKey={"parcelShipments.shipmentCard.showAll"}
                              >
                                Show all
                              </Trans>
                            )}
                            &nbsp;({orderAttachments.length})
                          </Typography>
                        ) : null}
                      </Grid>
                    </Box>
                    <Collapse
                      in={
                        expandPhotos ||
                        (orderAttachments && orderAttachments.length <= 5) ||
                        false
                      }
                      collapsedSize={
                        imagesList.current?.clientHeight ?? 50 + 20
                      }
                      timeout="auto"
                    >
                      <ImageList cols={5} gap={16}>
                        {orderAttachments?.map((item: AttachmentDto) => (
                          <ImageWithModal
                            key={item.attachmentId}
                            item={{
                              uri: item.presignedPreviewUri ?? "",
                              description: item?.description,
                            }}
                          />
                        ))}
                      </ImageList>
                    </Collapse>
                  </Box>
                </Grid>
              </>
            ) : null}
          </Box>

          {/* Shipment Cost */}
          {!isDesktop && (
            <Divider
              sx={{
                backgroundColor: "#D8E6FB",
                my: 4,
              }}
            />
          )}
          <Grid my={4}>
            <Box sx={isDesktop ? boxStyle : {}}>
              <Box
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => setExpandCost((prev) => !prev)}
                sx={{cursor: "pointer"}}
              >
                <Grid
                  md
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography
                    variant={isDesktop ? "h2" : "h3"}
                    sx={{fontSize: "18px !important"}}
                  >
                    <Trans i18nKey={"parcelShipments.shipmentDetails.cost"}>
                      Shipment Cost
                    </Trans>
                  </Typography>
                  <Grid
                    display={"flex"}
                    alignItems={"center"}
                    textAlign={"right"}
                  >
                    <Typography color={"#3E80FF"}>
                      {expandCost ? (
                        <Trans
                          data-testid="shipment-hide-details-coast"
                          i18nKey={"parcelShipments.shipmentCard.hideDetails"}
                        >
                          Hide all
                        </Trans>
                      ) : (
                        <Trans
                          data-testid="shipment-show-details-coast"
                          i18nKey={"parcelShipments.shipmentCard.showDetails"}
                        >
                          Show all
                        </Trans>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Collapse in={expandCost} timeout="auto">
                <Grid container columns={12} mt={3} flexDirection={"column"}>
                  {parcelShipment.invoices?.map((invoice) => {
                    return (
                      <Grid
                        key={`invoice-${invoice.accountingTransactionId}`}
                        display={"flex"}
                        mb={2}
                      >
                        <Typography variant="body4">
                          <Trans
                            i18nKey={
                              "parcelShipments.shipmentDetails.invoiceNumber"
                            }
                          >
                            Invoice number
                          </Trans>
                        </Typography>
                        {invoice.accountingTransactionId &&
                        invoice.transactionNumber ? (
                          <Typography
                            data-testid="invoice-transaction-number"
                            variant="body3"
                            pl={1}
                          >
                            <Link
                              to={getInvoiceLink(invoice)}
                              data-testid={"link-to-order-details"}
                              sx={{
                                lineBreak: "anywhere",
                                textDecoration: "none",
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                              }}
                              onClick={() => {
                                window.scrollTo(0, 0);
                              }}
                              color={"primary"}
                            >
                              {invoice.transactionNumber}
                            </Link>
                          </Typography>
                        ) : (
                          <Typography variant="body3" pt={0.5}>
                            -
                          </Typography>
                        )}
                        {invoice.accountingTransactionId && (
                          <Chip
                            data-testid="accounting-transaction-status"
                            color={
                              invoice.accountingTransactionStatus &&
                              invoice.accountingTransactionStatus == "Paid"
                                ? "success"
                                : "error"
                            }
                            label={
                              invoice.accountingTransactionStatus?.toLowerCase() ===
                              "paid"
                                ? t("invoices.statuses.paid")
                                : t("invoices.statuses.notPaid")
                            }
                            sx={{
                              marginLeft: "auto",
                              minWidth: "74px",
                              height: "24px",
                              ...getInvoiceStatusColors(
                                invoice.accountingTransactionStatus ?? "Void",
                              ),
                              fontSize: "16px",
                            }}
                          />
                        )}
                      </Grid>
                    );
                  })}
                  {groupedCharges.map((group) => {
                    return renderCharge(
                      group.name,
                      getFormattedPriceByInvoice(
                        {
                          description: group.charges[0].description,
                          totalAmount: group.totalAmount,
                          price: group.totalPrice,
                          rate: {estimatedCost: group.estimatedCost},
                        },
                        !!(
                          parcelShipment.invoices &&
                          parcelShipment.invoices.length > 0
                        ),
                        config?.showEstimatedCost,
                      ),
                      group.testId,
                    );
                  })}
                  {groupedCharges.length > 0 &&
                    !config?.showEstimatedCost &&
                    renderCharge(
                      <b>{t("delivery.totalCost.total")}</b>,
                      <b>{getFormattedPrice(totalCost)}</b>,
                      "total-cost",
                    )}
                </Grid>
              </Collapse>
            </Box>
          </Grid>
        </Grid>

        <Grid
          xs={12}
          md={5}
          lg={6}
          pr={{md: 5, xs: 0}}
          mt={{xs: 3, md: 0, lg: 0}}
          lgOffset={1}
          mdOffset={1}
          pb={4}
        >
          <Typography variant={"h2"} mb={3} mt={4}>
            <Trans i18nKey={"parcelShipments.shipmentDetails.shipmentProcess"}>
              Shipment Process
            </Trans>
          </Typography>
          <PrintLabel parcelShipment={parcelShipment} />
          <ShipmentProcess parcelShipment={parcelShipment} />
        </Grid>
      </Grid>

      {parcelShipment.invoices && parcelShipment.invoices.length > 0 && (
        <Grid lg={2} md={3} xs={6} mb={5}>
          {getFirstOpenInvoice() ? (
            <Button
              type="button"
              variant="contained"
              color={"secondary"}
              component={Link}
              sx={{width: "100%", marginTop: 3}}
              to={getInvoiceLink(getFirstOpenInvoice())}
            >
              {t("invoices.payInvoice")}
            </Button>
          ) : parcelShipment.invoices.length == 1 ? (
            <Button
              type="button"
              variant="outlined"
              color={"primary"}
              component={Link}
              sx={{width: "100%", marginTop: 3}}
              to={getInvoiceLink(parcelShipment.invoices[0])}
            >
              {t("invoices.goToInvoice")}
            </Button>
          ) : null}
        </Grid>
      )}
    </>
  );
}
