import {useAppSelector} from "../../store";
import {
  CountriesGetApiArg,
  useCountriesGetQuery,
} from "../../features/countries/countries-api";

export enum CountryFilter {
  Default = "default",
  Delivery = "delivery",
  PhoneNumber = "phoneNumber",
}

interface UseGetCountriesProps {
  filter?: CountryFilter;
}

export const useGetCountries = ({
  filter = CountryFilter.Default,
}: UseGetCountriesProps) => {
  const defaultArgs: CountriesGetApiArg = {
    organizationId: process.env
      .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
    filter: useAppSelector(
      (state: any) =>
        state.organizationConfigState?.modules?.countryFilter?.[filter],
    ),
  };

  const {data, isError, isLoading, isSuccess, error} =
    useCountriesGetQuery(defaultArgs);

  return {
    data,
    isError,
    error,
    isLoading,
    isSuccess,
  };
};
