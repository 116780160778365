import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {useAppSelector} from "../../../store";
import {Trans} from "react-i18next";
import {
  CommodityDto,
  ContactGQLDto,
  ModeOfTransportationDto,
} from "../../../features/order/order-api";
import {useContactAddressGetContactAddressQuery} from "../../../features/contact/contact-api";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../../features/workflowTriggers/workflowExecution-api";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {getFormattedPrice} from "../../../../utils/formatCurrency.utils";
import i18next from "i18next";
import {
  deliveryMethodBoxSX,
  deliveryMethodBoxSXisActive,
} from "./deliveryMethodCard";
import {
  setDeliveryMethod,
  setDeliveryToTrtMethod,
} from "../../../features/parcel/parcel-slice";
import {toastError} from "../../../common/utils/toastMessages";
import {AttachmentDto} from "../../../features/attachment/attachment-api";
import {getCustomValue} from "../../../../utils/helper.utils";
import parse from "html-react-parser";

export type DeliveryMethodMatrixDto = {
  carrier?: ContactGQLDto & {attachments?: AttachmentDto[]};
  firstMileRateId?: number;
  lastMileRateId?: number;
  totalAmount?: number;
  modeOfTransportation?: ModeOfTransportationDto;
  rateCustomValues?: {[key: string]: string};
  rates?: DeliveryMethodMatrixDto[];
  transitDaysMin?: number;
  transitDaysMax?: number;
};

const AllowedTransportationMethods = ["Air", "Ocean"];

export default function DeliveryInUSMethodList() {
  const theme = useTheme();
  const lang = i18next.language;
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const currentOrder = useAppSelector((state: any) => state.parcelState.order);
  const selectedLastMileMethod = currentOrder.deliveryMethod;

  const workflowId = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.workflows?.getRatesMatrixWorkflowId,
  );
  const userId = useAppSelector((state: any) => state.userState.contactId);

  const {data: consigneeAddressData, isSuccess: consigneeAddressIsSuccess} =
    useContactAddressGetContactAddressQuery({
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      contactAddressId: currentOrder.consigneeAddressValues.contactAddressId,
    });
  const {data: contactAddressData, isSuccess: contactAddressIsSuccess} =
    useContactAddressGetContactAddressQuery({
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      contactAddressId: currentOrder.contactAddressValues.contactAddressId,
    });

  const [runWorkflow] = useWorkflowExecutionMutation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deliveryMethodsMatrix, setDeliveryMethodsMatrix] = useState<
    DeliveryMethodMatrixDto[]
  >([]);

  const [container, setContainer] = useState<CommodityDto | null>(null);

  useEffect(() => {
    if (currentOrder && currentOrder.container) {
      const container: CommodityDto = {
        commodityId: 0,
        description: "Box Container",
        pieces: 1,
        customValues: currentOrder.container.customValues,
        dimensionsUnit: currentOrder.container.dimensionsUnit,
        volumeUnit: currentOrder.container.volumeUnit,
        weightUnit: currentOrder.container.weightUnit,
        length: Number(currentOrder.container.length),
        width: Number(currentOrder.container.width),
        height: Number(currentOrder.container.height),
        weight: Number(currentOrder.container.weight),
        weightTotal: Number(currentOrder.container.weight),
        unitaryValue: currentOrder.container.unitaryValue,
      };
      setContainer(container);
    }
  }, [currentOrder?.container]);

  useEffect(() => {
    setIsLoading(true);
    if (consigneeAddressIsSuccess && contactAddressIsSuccess && container) {
      const workflowData = {
        finalMileDestinationCountryCode: consigneeAddressData?.countryCode,
        finalMileAdditionalServices: null,
        customerId: userId,
        commodities: [container],
        isDangerousGoods: container.customValues?.dangerousItems != "",
        isPickupLocation:
          consigneeAddressData?.customValues?.is_pickup_location == "true",
      };

      const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        workflowId: workflowId,
        values: {variables: {...workflowData}},
      };

      runWorkflow(executeWorkflowApiArgs)
        .then((response: any) => {
          setDeliveryMethodsMatrix(response?.data?.outputs?.quoteMatrix);
        })
        .catch((error) => {
          console.log(error);
          toastError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [contactAddressData, consigneeAddressData, container]);

  const handleClick = (
    firstMileMethod: DeliveryMethodMatrixDto,
    lastMileMethod: DeliveryMethodMatrixDto,
  ) => {
    if (firstMileMethod && lastMileMethod.lastMileRateId) {
      dispatch(
        setDeliveryToTrtMethod({
          deliveryMethod: {
            carrier: firstMileMethod.carrier,
          },
        }),
      );
      dispatch(
        setDeliveryMethod({
          deliveryMethod: {
            rateId: lastMileMethod.lastMileRateId,
            deliveryType: lastMileMethod.rateCustomValues,
            carrier: lastMileMethod.carrier,
          },
        }),
      );
    }
  };

  const deliveryMethodIsSelected = (
    firstMileRate: DeliveryMethodMatrixDto,
    lastMileRate: DeliveryMethodMatrixDto,
  ) => {
    return (
      firstMileRate &&
      lastMileRate?.lastMileRateId &&
      selectedLastMileMethod?.rateId &&
      lastMileRate?.lastMileRateId == selectedLastMileMethod?.rateId
    );
  };

  return (
    <>
      {!deliveryMethodsMatrix?.length && !isLoading ? (
        <Grid sx={{display: "flex", justifyContent: "start", mt: 2}}>
          <Typography variant="body4">
            <Trans i18nKey="delivery.deliveryMethods.noDeliveryMethods">
              No delivery methods yet
            </Trans>
          </Typography>
        </Grid>
      ) : (
        <>
          {isLoading ? (
            <Grid sx={{display: "flex", justifyContent: "center", mt: 3}}>
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <Grid spacing={3} container>
                <Table
                  sx={{
                    "& .MuiTableCell-root": {
                      verticalAlign: "top",
                      borderBottom: "none",
                      display: isDesktop ? "table-cell" : "inline-block",
                    },
                    "& .MuiTableRow-root": {
                      verticalAlign: "top",
                      borderBottom: "none",
                      display: isDesktop ? "table-row" : "block",
                    },
                  }}
                  border={0}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      {isDesktop &&
                        AllowedTransportationMethods.map((method) => {
                          return (
                            <TableCell key={`method-th-${method}`}>
                              <Typography variant="h2">
                                <Trans
                                  i18nKey={`delivery.deliveryMethods.transportMethods.${method}`}
                                >
                                  {method}
                                </Trans>
                              </Typography>
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deliveryMethodsMatrix
                      ?.filter((x) => x.rates)
                      .map(
                        (
                          firstMileMethod: DeliveryMethodMatrixDto,
                          index: number,
                        ) => (
                          <TableRow key={index}>
                            <TableCell
                              sx={{
                                width: isDesktop ? "30%" : "100%",
                              }}
                            >
                              <Grid
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  padding: 0,
                                }}
                              >
                                {firstMileMethod.carrier?.attachments &&
                                  firstMileMethod.carrier?.attachments.length >
                                    0 && (
                                    <Box sx={{maxWidth: "300px"}}>
                                      <img
                                        src={
                                          firstMileMethod.carrier
                                            ?.attachments[0]
                                            .presignedPreviewUri ?? ""
                                        }
                                        alt={
                                          firstMileMethod.carrier?.name ?? ""
                                        }
                                        style={{maxWidth: "100px"}}
                                      ></img>
                                    </Box>
                                  )}
                                <Typography variant="h2">
                                  {getCustomValue(
                                    firstMileMethod?.carrier?.customValues,
                                    `portal_carrier_name`,
                                  ) ?? firstMileMethod.carrier?.name}
                                </Typography>
                                <Typography
                                  variant="caption3"
                                  sx={{paddingTop: "0.5rem"}}
                                >
                                  {parse(
                                    getCustomValue(
                                      firstMileMethod?.carrier?.customValues,
                                      `carrier_instructions_${lang}`,
                                    ) ?? "",
                                  ) ?? ""}
                                </Typography>
                              </Grid>
                            </TableCell>
                            {AllowedTransportationMethods.map(
                              (transportMethod) => {
                                return (
                                  <TableCell
                                    key={`method-tb-${transportMethod}`}
                                    sx={{
                                      display: "inline-block",
                                      width: isDesktop ? "auto" : "100%",
                                    }}
                                  >
                                    {!isDesktop && (
                                      <Typography variant="h2" sx={{mb: 2}}>
                                        <Trans
                                          i18nKey={`delivery.deliveryMethods.transportMethods.${transportMethod}`}
                                        >
                                          {transportMethod}
                                        </Trans>
                                      </Typography>
                                    )}
                                    {firstMileMethod.rates
                                      ?.filter(
                                        (x) =>
                                          x.modeOfTransportation
                                            ?.transportationMethod ==
                                          transportMethod,
                                      )
                                      .map(
                                        (
                                          lastMileMethod: DeliveryMethodMatrixDto,
                                          index: number,
                                        ) => {
                                          return (
                                            <Box
                                              key={`card-delivery-method-${
                                                firstMileMethod.carrier?.name ??
                                                "carrier"
                                              }-${transportMethod}-${index}`}
                                              data-testid={`card-delivery-method-${
                                                firstMileMethod.carrier?.name ??
                                                "carrier"
                                              }-${transportMethod}-${index}`}
                                              style={{
                                                cursor:
                                                  deliveryMethodIsSelected(
                                                    firstMileMethod,
                                                    lastMileMethod,
                                                  )
                                                    ? "default"
                                                    : "pointer",
                                                padding: 16,
                                                margin: "0 0 8px",
                                              }}
                                              sx={
                                                deliveryMethodIsSelected(
                                                  firstMileMethod,
                                                  lastMileMethod,
                                                )
                                                  ? deliveryMethodBoxSXisActive
                                                  : deliveryMethodBoxSX
                                              }
                                              onClick={() =>
                                                handleClick(
                                                  firstMileMethod,
                                                  lastMileMethod,
                                                )
                                              }
                                            >
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                                className="deliveryDays"
                                              >
                                                <Box>
                                                  <Typography
                                                    className="deliveryDaysText"
                                                    variant="body1"
                                                  >
                                                    {lastMileMethod.transitDaysMin && (
                                                      <>
                                                        <Trans i18nKey="deliveryMethodFrom">
                                                          from
                                                        </Trans>
                                                        &nbsp;
                                                        {
                                                          lastMileMethod.transitDaysMin
                                                        }
                                                        &nbsp;
                                                      </>
                                                    )}
                                                    {lastMileMethod.transitDaysMax && (
                                                      <>
                                                        <Trans i18nKey="deliveryMethodTo">
                                                          to
                                                        </Trans>
                                                        &nbsp;
                                                        {
                                                          lastMileMethod.transitDaysMax
                                                        }
                                                        &nbsp;
                                                      </>
                                                    )}

                                                    <Trans i18nKey="deliveryMethodDays">
                                                      Days
                                                    </Trans>
                                                  </Typography>
                                                </Box>
                                              </Box>
                                              <Box>
                                                <Typography
                                                  className="deliveryType"
                                                  sx={{color: "#6C757D"}}
                                                  variant="caption3"
                                                >
                                                  {(lastMileMethod.rateCustomValues &&
                                                    `service_description_${lang}` in
                                                      lastMileMethod.rateCustomValues &&
                                                    lastMileMethod
                                                      .rateCustomValues[
                                                      `service_description_${lang}`
                                                    ]) ??
                                                    ""}
                                                </Typography>
                                              </Box>
                                              <Box mt={1}>
                                                <Typography
                                                  className="deliveryPrice"
                                                  align="right"
                                                  color="#2F81EF"
                                                  variant="body1"
                                                  sx={{fontWeight: 600}}
                                                >
                                                  {getFormattedPrice(
                                                    lastMileMethod.totalAmount ??
                                                      0,
                                                  )}
                                                </Typography>
                                              </Box>
                                            </Box>
                                          );
                                        },
                                      )}
                                  </TableCell>
                                );
                              },
                            )}
                          </TableRow>
                        ),
                      )}
                  </TableBody>
                </Table>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
}
