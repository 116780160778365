import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link as ExternalLink,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Checkbox, TextField} from "formik-mui";
import {Trans, useTranslation} from "react-i18next";
import {getRegisterSchema} from "../pages/validation";
import {RegisterData} from "./interfaces";
import Link from "../../common/components/link";
import GoogleIcon from "../../../images/Google.svg";
import {CreateUserCommandValues} from "../../features/user/user-api";
import {ArrowBackIos, Visibility, VisibilityOff} from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2";
import {useSignUpWithGoogle} from "../hooks/useSignUpWithGoogle";
import DividingOr from "../../common/components/dividingOr";
import {useSearchParams} from "react-router-dom";
import i18next from "i18next";
import {toastError} from "../../common/utils/toastMessages";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../features/workflowTriggers/workflowExecution-api";
import {useAppSelector} from "../../store";
import ReCAPTCHA from "react-google-recaptcha";
import {getPortalNameFromDomain} from "../../common/OrganizationConfig/useOrganizationConfig";

const PASSWORD_REQUIREMENTS: {label: string; i18nKey: string}[] = [
  {label: "At least one uppercase ('A'-'Z')", i18nKey: "atLeastOneUppercase"},
  {label: "At least one lowercase ('a'-'z')", i18nKey: "atLeastOneLowercase"},
  {label: "At least one special character", i18nKey: "atLeastOneSpecial"},
  {label: "At least one digit", i18nKey: "atLeastOneDigit"},
  {label: "At least 6 characters in length", i18nKey: "atLeastSixCharacters"},
];

const skipCaptcha =
  (process.env.REACT_APP_PORTAL_SKIP_CAPTCHA as unknown as string) === "true";

const initialValues = {
  password: "",
  email: "",
  promoCode: "",
  captchaVerified: skipCaptcha ?? false,
  termsCheck: false,
  source:
    process.env.REACT_APP_PORTAL_NAME ?? getPortalNameFromDomain() == "portal"
      ? "shopping"
      : "parcels",
};

export type RegisterFormValues = CreateUserCommandValues & {
  captchaVerified: boolean;
};

export default function Register({
  onSubmit,
  googleSubmit,
  isLoading,
  promoCodeErrors,
}: RegisterData) {
  const {t} = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [searchParams] = useSearchParams();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showPromoCode, setShowPromocode] = React.useState<boolean>(false);
  const [warning, setWarning] = useState("");
  const [showToast, setShowToast] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword((value) => !value);
  };
  const lang = i18next?.language ?? "en";

  const sendEmailWorkflowId = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.workflows?.sendVerifyEmailWorkflowId,
  );
  const [runWorkflow] = useWorkflowExecutionMutation();

  const promoCode =
    searchParams.get("promoCode") ?? sessionStorage.getItem("_promoCode");

  useEffect(() => {
    if (promoCode) {
      setShowPromocode(true);
    }
  }, []);

  useEffect(() => {
    if (promoCodeErrors) {
      setWarning(promoCodeErrors[i18next.language]);
    } else {
      setWarning("");
    }
  }, [promoCodeErrors]);

  const termsCheckboxLabel = (
    <div style={{fontSize: "0.8rem", textAlign: "justify"}}>
      <Trans i18nKey="conditions">
        I agree with&nbsp;
        <ExternalLink
          data-testid={`link-terms-and-conditions`}
          href={`https://trtshopping.com/ua/${
            lang == "ua" ? "" : lang + "/"
          }terms-and-conditions/`}
          target="_blank"
          key={"p1age"}
          rel="noopener"
        >
          Terms & Conditions
        </ExternalLink>
        &nbsp;and&nbsp;
        <ExternalLink
          data-testid={`link-privacy-policy`}
          href={`https://trtshopping.com/ua/${
            lang == "ua" ? "" : lang + "/"
          }privacy-policy/`}
          target="_blank"
          rel="noopener"
        >
          Privacy Policy
        </ExternalLink>
      </Trans>
    </div>
  );

  const promoCheckboxLabel = (
    <div style={{fontSize: "0.8rem", textAlign: "justify"}}>
      <Trans i18nKey="discount.codeLabel">
        I have an Invite code (optional)
      </Trans>
    </div>
  );

  const {signUpWithGoogle} = useSignUpWithGoogle(googleSubmit);

  const validateWithToast = (values: RegisterFormValues) => {
    const schema = getRegisterSchema(t);
    try {
      schema.validateSync(values, {abortEarly: false});
    } catch (errors: any) {
      if (showToast) {
        const passwordError = errors.inner.filter(
          (error: any) => error.path === "password",
        )?.[0]?.message;
        const emailError = errors.inner.filter(
          (error: any) => error.path === "email",
        )?.[0]?.message;
        const termsError = errors.inner.filter(
          (error: any) => error.path === "termsCheck",
        )?.[0]?.message;

        const messages = [
          passwordError || "",
          emailError || "",
          termsError || "",
        ];
        messages.forEach((message) => message && toastError(message));
        setShowToast(false);
      }
    }
  };

  const sendRegistrationEmailCode = async (email: string) => {
    const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      workflowId: sendEmailWorkflowId,
      values: {
        variables: {
          userEmail: email,
        },
      },
    };
    const sendEmailResponse = await runWorkflow(executeWorkflowApiArgs);

    const workflowError = (sendEmailResponse as any)?.error;

    if (workflowError) {
      toastError(workflowError?.data?.errors?.General?.[1]);
    }
  };

  const registerUser = async (values: RegisterFormValues) => {
    if (!showPromoCode) {
      values.promoCode = "";
    }

    try {
      const submitSuccess = await onSubmit(values);

      if (submitSuccess && values.email)
        await sendRegistrationEmailCode(values.email);
    } catch (error) {
      toastError(error);
    }
  };

  return (
    <Grid
      container
      columns={{xs: 6, md: 6}}
      rowSpacing={{xs: 3, md: 4}}
      height={"100%"}
      justifyContent="center"
    >
      <Grid xs={6} md={4} pb={0} position={"relative"}>
        <Grid display={{xs: "none", md: "flex"}}>
          <Link to="../login">
            <ArrowBackIos sx={{color: "black"}} />
          </Link>
        </Grid>
        <Typography
          sx={{
            textAlign: {xs: "left", md: "center"},
            fontSize: "2rem",
            fontFamily: "Inter SemiBold",
            pt: "40px",
          }}
        >
          {isDesktop ? (
            <Trans i18nKey="signUp">Register a new account</Trans>
          ) : (
            <Trans i18nKey="signUpMobile">Registration</Trans>
          )}
        </Typography>
        <Formik
          onSubmit={registerUser}
          initialValues={{
            ...initialValues,
            email: searchParams.get("email") ?? "",
            promoCode: promoCode ?? "",
          }}
          validationSchema={getRegisterSchema(t)}
          validate={validateWithToast}
        >
          {(formikContext) => (
            <Form autoComplete="off">
              <Grid display={"flex"} pb={0}>
                <Grid xs={6} md={6}>
                  <Button
                    variant="outlined"
                    fullWidth
                    data-testid="btn-google"
                    onClick={() => signUpWithGoogle()}
                  >
                    <Box
                      component="img"
                      src={GoogleIcon}
                      alt="google-icon"
                      pr={"0.7rem"}
                    />
                    Google
                  </Button>
                </Grid>
                {/*<Grid xs={3} md={3} pl={"0.5rem"}>
                  <Button
                    variant="outlined"
                    fullWidth
                    data-testid="btn-facebook"
                    onClick={() => facebookLogin(lang)}
                  >
                    <Box
                      component="img"
                      src={FacebookIcon}
                      alt="facebook-icon"
                      pr={"0.7rem"}
                    />
                    Facebook
                  </Button>
                </Grid>*/}
              </Grid>
              <Grid
                display={"flex"}
                justifyContent="center"
                py={0}
                color={"gray"}
                textAlign={"center"}
              >
                <DividingOr />
              </Grid>
              <Grid>
                <Field
                  fullWidth
                  component={TextField}
                  name="email"
                  type="email"
                  size={isDesktop ? "medium" : "small"}
                  label={t("email")}
                  placeholder="example@gmail.com"
                  data-testid="input-email"
                  inputProps={{
                    autoComplete: "off",
                    form: {
                      autocomplete: "off",
                    },
                  }}
                />
              </Grid>
              <Grid py={0}>
                <Field
                  fullWidth
                  component={TextField}
                  size={isDesktop ? "medium" : "small"}
                  label={t("password")}
                  name="password"
                  data-testid="input-password"
                  autoComplete={"new-password"}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          data-testid="btn-show-password"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                          sx={{opacity: "0.5"}}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid py={0} mt="27px">
                <Typography component="div" variant="caption2" color="#393A46">
                  <Trans i18nKey="passwordRequirements">
                    Password Requirements
                  </Trans>
                </Typography>
                <Box component="ul" mt="8px" pl="24px">
                  {PASSWORD_REQUIREMENTS.map(({label, i18nKey}) => (
                    <Typography
                      component="li"
                      variant="caption3"
                      color="primary.dark"
                      key={i18nKey}
                    >
                      <Trans i18nKey={i18nKey}>{label}</Trans>
                    </Typography>
                  ))}
                </Box>
              </Grid>
              {!skipCaptcha ? (
                <Grid>
                  <ReCAPTCHA
                    sitekey={
                      process.env
                        .REACT_APP_PORTAL_RECAPTCHA_V2_SITE_KEY as unknown as string
                    }
                    onChange={(value) => {
                      const isVerified = !!value;
                      formikContext.setFieldValue(
                        "captchaVerified",
                        isVerified,
                        true,
                      );
                    }}
                  />
                  <ErrorMessage
                    name="captchaVerified"
                    render={(msg: any) => (
                      <Typography
                        variant="subtitle2"
                        color="error"
                        sx={{fontWeight: "light", mb: 1}}
                      >
                        {msg}
                      </Typography>
                    )}
                  />
                </Grid>
              ) : null}
              <Grid pt={1}>
                <FormControlLabel
                  control={
                    <Field
                      component={Checkbox}
                      type="checkbox"
                      name=""
                      color="primary"
                      data-testid="input-termsCheck"
                      sx={{color: "#ABABAB", padding: "0 9px"}}
                      onClick={() => setShowPromocode(!showPromoCode)}
                      checked={showPromoCode}
                    />
                  }
                  label={promoCheckboxLabel}
                  sx={{display: "flex", alignItems: "start"}}
                />
              </Grid>
              {showPromoCode && (
                <Grid py={0}>
                  <Field
                    fullWidth
                    component={TextField}
                    size={isDesktop ? "medium" : "small"}
                    label={t("discount.invitationCode")}
                    name="promoCode"
                    data-testid="promoCode"
                    autoComplete={"new-promocode"}
                    type={"text"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end"></InputAdornment>
                      ),
                    }}
                  />
                  {warning && (
                    <ErrorMessage
                      name=""
                      render={() => (
                        <Typography
                          variant="subtitle2"
                          color="error"
                          sx={{fontWeight: "light", mb: 1}}
                        >
                          {warning}
                        </Typography>
                      )}
                    />
                  )}
                </Grid>
              )}
              <Grid pt={1}>
                <FormControlLabel
                  control={
                    <Field
                      component={Checkbox}
                      type="checkbox"
                      name="termsCheck"
                      color="primary"
                      data-testid="input-termsCheck"
                      sx={{color: "#ABABAB", padding: "0 9px"}}
                    />
                  }
                  label={termsCheckboxLabel}
                  sx={{display: "flex", alignItems: "start"}}
                />

                <ErrorMessage
                  name="termsCheck"
                  render={(msg: any) => (
                    <Typography
                      variant="subtitle2"
                      color="error"
                      sx={{fontWeight: "light", mb: 1}}
                    >
                      {msg}
                    </Typography>
                  )}
                />
              </Grid>
              <Grid xs={6}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="secondary"
                  data-testid="btn-submit"
                  disabled={isLoading}
                  onClick={() => setShowToast(true)}
                >
                  <Trans i18nKey="signUpButton">Register</Trans>
                </Button>
                <Typography variant="body1" textAlign={"center"} pt={"32px"}>
                  <Trans i18nKey="loginQuestion">
                    Already have an account?&nbsp;
                    <Link to="../login" underline="none">
                      Log In
                    </Link>
                  </Trans>
                </Typography>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
