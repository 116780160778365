import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import ConfirmationEditStepCard from "./confirmationEditStepCard";
import {useParcelShipmentSubmit} from "../../hooks/UseParcelShipmentSubmit";
import {
  ChargeDto,
  CommodityDto,
  CreateChargeCommand,
  CreateCommodityPortalCommand,
  OrderDto,
} from "../../../features/order/order-api";
import {useAppSelector} from "../../../store";
import {LoadingButton} from "@mui/lab";
import ModalForm from "../../../common/Modal/components/modalForm";
import ConfirmationModal from "./confirmationModal";
import i18next from "i18next";
import TotalCost from "../../components/totalCost.component";
import ConfirmationEditPackageStepCard from "./confirmationEditPackageStepCard";
import {toastError} from "../../../common/utils/toastMessages";
import {getFormattedPrice} from "../../../../utils/formatCurrency.utils";
import {Helmet} from "react-helmet";
import {
  ContactAddressGetContactAddressApiArg,
  useLazyContactAddressGetContactAddressQuery,
} from "../../../features/contactAddress/contactAddress-api";
import {getLangCodeFromI18n} from "../../../app/app.component";
import {getConsigneeLocation} from "../../../ParcelShipments/AllParcelShipments/utils/parcelShipmentTable.utils";
import uuid from "react-uuid";
import {useConsolidationAccountingItem} from "../../../common/Consolidation/useConsolidationAccountingItem";
import {DeliveryConfiguration} from "../../delivery.module";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../../features/workflowTriggers/workflowExecution-api";
import {useDeliveryHelper} from "../../hooks/useDeliveryHelper";
import {useDispatch} from "react-redux";
import {deleteRelatedOrders} from "../../../features/order/parcelShipment-slice";

export default function ConfirmationFragment({
  switchFragment,
  isConsolidated,
  config,
}: {
  switchFragment: (stepKey: string) => void;
  isConsolidated: boolean;
  config?: DeliveryConfiguration;
}) {
  const lang = getLangCodeFromI18n();
  const {t} = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const {currentOrder: thisOrder, orderIds} = useDeliveryHelper();

  const workflowId = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.workflows?.createParcelWorkflowId,
  );

  const currentCustomerId = useAppSelector(
    (state) => state.userState.contactId,
  );

  const [fullOrder, setFullOrder] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [trackingNumber, setTrackingNumber] = useState<number | undefined>();
  const [consigneeAddressLines, setConsigneeAddressLines] = useState<string[]>(
    [],
  );
  const [additionalServices, setAdditionalServices] = useState<
    | {
        name: string;
        price: number;
        formattedPrice: string;
      }[]
    | null
  >([]);
  const [purchases, setPurchases] = useState<OrderDto[] | null>(null);
  const {getPurchase} = useParcelShipmentSubmit();
  const [calculatedCharges, setCalculatedCharges] = useState<ChargeDto[]>([]);
  const [parcelId, setParcelId] = useState<number>(0);
  const [consigneeAddress, setConsigneeAddress] = useState<any>(null);
  const [runWorkflow] = useWorkflowExecutionMutation();

  const [getContactAddress] = useLazyContactAddressGetContactAddressQuery();

  let consolidationItemCode = "";
  if (isConsolidated) {
    consolidationItemCode = useConsolidationAccountingItem() ?? "";
  }

  useEffect(() => {
    if (thisOrder?.consigneeAddressValues?.contactAddressId) {
      const getConsigneeAddressArgs: ContactAddressGetContactAddressApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        contactAddressId: thisOrder.consigneeAddressValues.contactAddressId,
      };

      getContactAddress(getConsigneeAddressArgs)
        .unwrap()
        .then((data) => setConsigneeAddress(data));
    }
  }, [thisOrder?.consigneeAddressValues?.contactAddressId]);

  useEffect(() => {
    if (consigneeAddress) {
      getConsigneeLocation(consigneeAddress, lang, t).then((result) => {
        setConsigneeAddressLines([
          [
            consigneeAddress?.contact?.contactFirstName,
            consigneeAddress?.contact?.contactLastName,
          ].join(" "),
          consigneeAddress?.contact?.phoneNumber ?? "",
          ...result.filter(
            (value) => value && value.length && value.length > 0,
          ),
        ]);
      });
    }
  }, [consigneeAddress, lang]);

  const getDeliveryMethod = () => {
    if (thisOrder?.deliveryMethod) {
      return (
        <Typography variant={"caption2"}>
          {thisOrder.deliveryMethod.deliveryType[
            `service_description_${i18next.language}`
          ] ?? "Not selected"}
        </Typography>
      );
    }
  };

  const getPurchases = async () => {
    if (orderIds?.length === 1) {
      getPurchase(orderIds[0]).then((response: any) => {
        setPurchases([response.data]);
      });
    } else if (orderIds && orderIds.length > 1) {
      const getPurchasePromises = orderIds?.map((orderId) => {
        return getPurchase(orderId);
      });
      const results = await Promise.all(getPurchasePromises);
      setPurchases(
        results
          ?.map((result: any) => {
            if (result?.data) return result?.data;

            return null;
          })
          .filter((x) => !!x),
      );
    }
  };

  useEffect(() => {
    getPurchases();
  }, [orderIds]);

  useEffect(() => {
    setAdditionalServices(
      thisOrder.charges
        .filter(
          (x: CreateChargeCommand | any) =>
            x.values?.accountingItemType !== "Freight",
        )
        .map((service: CreateChargeCommand) => {
          const insCharge = thisOrder?.charges?.find(
            (c: any) =>
              c.values?.accountingItemCode ===
              config?.insuranceAccountingItemCode,
          );
          const consCharge = thisOrder?.charges?.find(
            (c: any) => c.values?.accountingItemCode === consolidationItemCode,
          );
          if (service.values?.description == "Insurance") {
            const price = insCharge?.values?.totalAmount?.toFixed(2);
            const formattedPrice = price
              ? getFormattedPrice(Number(price))
              : "free";
            if (price && formattedPrice) {
              return {
                name: t("delivery.additionalServices.insurance"),
                price,
                formattedPrice,
              };
            }
          } else if (
            consCharge &&
            service.values?.accountingItem?.itemCode === consolidationItemCode
          ) {
            const price = consCharge?.values?.totalAmount?.toFixed(2);
            const formattedPrice = price
              ? getFormattedPrice(Number(price))
              : "free";
            if (price && formattedPrice) {
              return {
                name: t("delivery.additionalServices.consolidation"),
                price,
                formattedPrice,
              };
            }
          } else {
            const price = (service.values?.price ?? 0).toFixed(2);
            const formattedPrice = price
              ? getFormattedPrice(Number(price))
              : "free";

            if (price) {
              return {
                name:
                  service.values?.accountingItem?.customValues &&
                  `service_description_${i18next.language}` in
                    service.values.accountingItem.customValues
                    ? service.values?.accountingItem?.customValues[
                        `service_description_${i18next.language}`
                      ]
                    : service.values?.description,
                price,
                formattedPrice,
              };
            }
          }
        }),
    );
  }, [i18next.language, consolidationItemCode]);

  useEffect(() => {
    const charges: ChargeDto[] = thisOrder?.charges?.map((charge: any) => {
      const calculatedCharge: ChargeDto = {
        accountingItemCode: charge?.values?.accountingItemCode,
        applyBy: charge?.values?.applyBy,
        accountingItemName:
          charge?.values?.accountingItem?.description ??
          charge?.values?.accountingItemName,
        totalAmount: charge?.values?.totalAmount,
        price: charge?.values?.price,
        accountingItemId: charge?.values?.accountingItemId,
        accountingItem: charge?.values?.accountingItem,
      };
      return calculatedCharge;
    });
    setCalculatedCharges(charges);
    setFullOrder(thisOrder);
  }, []);

  useEffect(() => {
    const commodities: CreateCommodityPortalCommand[] = [];
    const purchaseOrderIds: number[] = [];
    purchases?.forEach((purchase) => {
      if (purchase.orderId) purchaseOrderIds.push(purchase.orderId);
      purchase?.commodities &&
        purchase?.commodities.forEach((commodity: CommodityDto) => {
          commodities.push({
            uuid: uuid(),
            commodityId: commodity.commodityId,
            description: commodity.description,
            note: commodity.note,
            quantity: commodity.quantity,
            pieces: commodity.pieces,
            unit: commodity.unit,
            unitaryValue: commodity.unitaryValue,
            weight: commodity.weight,
            customValues: {
              ...commodity.customValues,
              customer_declared_weight: commodity?.weight,
              customer_declared_weight_unit: commodity?.weightUnit,
            },
            commodityType: commodity.commodityType,
            weightUnit: commodity.weightUnit,
            dimensionsUnit: commodity.dimensionsUnit,
            warehouseLocationId: commodity.warehouseLocationId,
          });
        });
    });

    setFullOrder({
      ...thisOrder,
      commodities,
      purchaseOrderIds,
    });
  }, [purchases]);
  const handleOpen = () => setOpen(true);

  const handleSubmit = async () => {
    setIsLoading(true);

    const data: any = {
      customerId: currentCustomerId,
      consigneeContactId: fullOrder?.consigneeValues.contactId,
      contactAddressId: fullOrder?.consigneeAddressValues?.contactAddressId,
      commodities: fullOrder?.commodities,
      charges: fullOrder?.charges,
      customValues: {...fullOrder?.customValues, isConsolidated},
      purchaseOrderIds: fullOrder?.purchaseOrderIds,
      selectedRateId: fullOrder?.deliveryMethod?.rateId,
    };

    const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      workflowId: workflowId,
      values: {variables: {...data}},
    };

    try {
      const response: any = await runWorkflow(executeWorkflowApiArgs);
      if (
        response?.data?.outputs?.orderId &&
        response?.data?.outputs?.trackingNumber
      ) {
        setParcelId(response.data?.outputs?.orderId);
        setTrackingNumber(response.data?.outputs?.trackingNumber);
        handleOpen();
      } else {
        toastError(response);
      }
    } catch (error) {
      toastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{"Ship Request - Confirmation"}</title>
      </Helmet>
      <Grid
        container
        columns={{xs: 6, md: 13}}
        justifyContent={"space-between"}
        rowGap={{md: "24px", xs: "0"}}
        p={0}
        mx={{xs: "-16px", md: 0}}
      >
        {!isDesktop && (
          <Grid mt={2} mb={1} mx={2}>
            <Typography variant="h2">
              {t("delivery.statusBar.confirmation")}
            </Typography>
          </Grid>
        )}
        <ConfirmationEditStepCard
          key={"editAddress"}
          header={t("delivery.confirmation.editAddress")}
          content={consigneeAddressLines?.map((line: any, index: any) => {
            return (
              <Grid key={`addressline-${index}`}>
                <Typography variant={"caption2"}>{line}</Typography>
              </Grid>
            );
          })}
          switchStepCallback={() => switchFragment("address")}
        />
        <ConfirmationEditStepCard
          key={"editDeliveryMethod"}
          header={t("delivery.confirmation.editDeliveryMethod")}
          content={getDeliveryMethod()}
          switchStepCallback={() => switchFragment("method")}
        />
        <ConfirmationEditStepCard
          key={"editPaymentMethod"}
          header={t("delivery.confirmation.editPaymentMethod")}
          content={
            <Typography variant={"caption2"}>
              {thisOrder?.contactPaymentMethod?.description ?? "Not selected"}
            </Typography>
          }
          switchStepCallback={() => switchFragment("payment")}
        />
        <ConfirmationEditStepCard
          key={"editAdditionalServices"}
          header={t("delivery.confirmation.editAdditionalServices")}
          content={
            additionalServices?.length
              ? additionalServices.map((x, idx) => {
                  if (x?.name && x?.formattedPrice)
                    return (
                      <Grid>
                        <Typography
                          key={`editAdditionalServices-${idx}`}
                          variant={"caption2"}
                        >
                          {x.name} - {x.formattedPrice}
                        </Typography>
                      </Grid>
                    );
                })
              : t("notSelected")
          }
          switchStepCallback={() => switchFragment("additional")}
        />
        <ConfirmationEditPackageStepCard
          purchases={purchases}
          switchStepCallback={() => switchFragment("customs")}
        />
        <Grid xs={6} md={13} justifyContent={"center"} mx={{xs: "16px", md: 0}}>
          <Grid xs={6} md={5}>
            {isLoading ? (
              <Grid sx={{display: "flex", justifyContent: "start", my: 2}}>
                <CircularProgress />
              </Grid>
            ) : (
              <TotalCost
                sx={{
                  border: "1px solid #2F81EF",
                  borderRadius: "16px",
                  padding: {md: 3, xs: 1.5},
                  marginTop: 2,
                }}
                deliveryCostPerUnit={
                  config?.showTotalCost
                    ? getFormattedPrice(thisOrder?.deliveryMethod?.totalAmount)
                    : thisOrder?.deliveryMethod?.estimatedCost
                }
                charges={calculatedCharges}
                consolidationItemCode={consolidationItemCode}
                insuranceItemCode={config?.insuranceAccountingItemCode}
                showTotalCost={config?.showTotalCost}
              />
            )}
          </Grid>
        </Grid>
        <Grid
          xs={6}
          md={2}
          justifyContent={"center"}
          mt={{md: 0, xs: 2}}
          mb={{md: 5, xs: 1}}
          mx={{md: 0, xs: 2}}
        >
          <LoadingButton
            data-testid="btn-confirmation-shipment"
            fullWidth
            type="button"
            variant="contained"
            color="secondary"
            sx={{
              p: 1,
              mb: 4,
            }}
            loading={isLoading}
            loadingPosition="center"
            endIcon={<></>}
            onClick={() => handleSubmit()}
          >
            <Typography variant="body3" textTransform={"none"}>
              <Trans i18nKey="btnSubmitRequest">Send request</Trans>
            </Typography>
          </LoadingButton>
        </Grid>
        <ModalForm open={open} setOpen={setOpen}>
          <ConfirmationModal
            trackingNumber={trackingNumber}
            parcelId={parcelId}
            onClose={() => {
              dispatch(deleteRelatedOrders({orderIds}));
            }}
          />
        </ModalForm>
      </Grid>
    </>
  );
}
