import React, {useMemo} from "react";
import {Trans, useTranslation} from "react-i18next";
import {
  CircularProgress,
  FormHelperText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import Grid from "@mui/material/Unstable_Grid2";
import {VerificationStepSubmit} from "./interfaces";
import {LoadingButton} from "@mui/lab";
import {Form, Formik} from "formik";
import {getPhoneSchema} from "../pages/validation";
import {
  SendPhoneCodeCommand,
  useVerificationPhoneCodeMutation,
  VerificationPhoneCodeApiArg,
} from "../../features/verification/verification-api";
import {SubmitHandler} from "react-hook-form";
import {useAppSelector} from "../../store";
import {useDispatch} from "react-redux";
import {
  setPhoneNumber as setPhoneNumberAction,
  verifyPhoneNumber,
} from "../../features/verification/verification-slice";
import {toast} from "react-hot-toast";
import {
  setUserPhoneConfirmed,
  setUserPhoneNumber,
} from "../../features/user/user-slice";
import {
  CountryFilter,
  useGetCountries,
} from "../../common/hooks/useGetCountries";

export type PhoneFragmentAdditionalProps = {
  onSkip?: any;
};

export default function PhoneFragment({
  handleNext,
  onSkip,
}: VerificationStepSubmit & PhoneFragmentAdditionalProps) {
  const i18nKeyPrefix = "verification.phone.";
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const initialValues: SendPhoneCodeCommand = {
    phoneNumber: useAppSelector(
      (state) =>
        state.verificationState?.phoneNumber?.value?.phoneNumber ??
        state?.userState?.phoneNumber ??
        "+1",
    ),
    sendingType: useAppSelector(
      (state) =>
        state.verificationState?.phoneNumber?.value?.sendingType ?? "sms",
    ),
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [sendCode] = useVerificationPhoneCodeMutation();

  const {data: phoneNumberCountries, isLoading: isPhoneNumberCountriesLoading} =
    useGetCountries({
      filter: CountryFilter.PhoneNumber,
    });

  const phoneNumberCountryCodes = useMemo(() => {
    return phoneNumberCountries?.map(
      (country) => country?.countryCode?.toLowerCase() ?? "",
    );
  }, [phoneNumberCountries]);

  const onSubmit: SubmitHandler<SendPhoneCodeCommand> = async (values) => {
    const commandArgs: VerificationPhoneCodeApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      sendPhoneCodeCommand: values,
    };
    const response: any = await sendCode(commandArgs);
    if (response?.data === true) {
      dispatch(verifyPhoneNumber(initialValues));
      dispatch(setUserPhoneNumber(initialValues.phoneNumber));
      dispatch(setUserPhoneConfirmed());
      toast.success(`${t(i18nKeyPrefix + "phoneSaveSuccess")}`);
      if (handleNext) handleNext();
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      columns={{xs: 6, md: 6}}
      columnSpacing={2}
    >
      <Grid xs={6} md={6}>
        <Grid container direction="column">
          <Grid>
            <Grid container columns={{xs: 6, md: 6}}>
              <Grid xs={6} md={5}>
                <Typography variant="h3" component="h3" mb={3}>
                  <Trans i18nKey={i18nKeyPrefix + "subtitle"}>
                    Fill out your phone number
                  </Trans>
                </Typography>
              </Grid>
              <Grid xs={6} md={5}>
                <Grid container direction="column" columns={{xs: 6, md: 5}}>
                  <Formik
                    onSubmit={(
                      values: SendPhoneCodeCommand,
                      {setSubmitting},
                    ) => {
                      onSubmit(values);
                      setSubmitting(false);
                    }}
                    initialValues={initialValues}
                    validationSchema={getPhoneSchema(t)}
                  >
                    {({isSubmitting, errors, values, touched}) => (
                      <Form id="sendLinkForm">
                        <Grid md={5}>
                          <Grid
                            container
                            direction="column"
                            columns={{xs: 6, md: 5}}
                          >
                            <Grid md={5}></Grid>
                            <Grid md={3} xs={6}>
                              {isPhoneNumberCountriesLoading ? (
                                <CircularProgress size={30} />
                              ) : (
                                <>
                                  <MuiPhoneNumber
                                    sx={{"& svg": {height: "0.8em"}}}
                                    disableAreaCodes={true}
                                    fullWidth
                                    size={isDesktop ? "medium" : "small"}
                                    id="phoneNumber"
                                    label={t("phoneNumber")}
                                    name="phoneNumber"
                                    autoComplete="phoneNumber"
                                    variant="outlined"
                                    defaultCountry={"us"}
                                    excludeCountries={["ru"]}
                                    onlyCountries={phoneNumberCountryCodes}
                                    value={initialValues.phoneNumber}
                                    onChange={(e: any) => {
                                      values.phoneNumber = e
                                        .replace(/\(/g, "")
                                        .replace(/\)/g, "")
                                        .replace(/-/g, "")
                                        .replace(/ /g, "");
                                      dispatch(setPhoneNumberAction(values));
                                    }}
                                    disabled={isSubmitting}
                                    error={Boolean(
                                      touched.phoneNumber && errors.phoneNumber,
                                    )}
                                  />
                                  <FormHelperText
                                    sx={{color: "#d32f2f", mt: 1, ml: 2}}
                                  >
                                    {touched.phoneNumber && errors.phoneNumber
                                      ? errors.phoneNumber
                                      : ""}
                                  </FormHelperText>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          xs={6}
                          md={6}
                          sx={{
                            position: {xs: "fixed", md: "inherit"},
                            bottom: {xs: "72px"},
                            pr: {xs: "26px", md: 0},
                            mt: {xs: 3, md: 3},
                          }}
                        >
                          <Grid
                            container
                            direction="row"
                            columns={{xs: 6, md: 6}}
                            display={"flex"}
                          >
                            {onSkip ? (
                              <Grid md={2} xs={3}>
                                <LoadingButton
                                  fullWidth
                                  variant="outlined"
                                  color="primary"
                                  sx={{textTransform: "none"}}
                                  disabled={isSubmitting}
                                  loading={isSubmitting}
                                  loadingPosition="end"
                                  endIcon={<></>}
                                  onClick={() => {
                                    onSkip();
                                  }}
                                >
                                  <Trans i18nKey={i18nKeyPrefix + "skip"}>
                                    Skip
                                  </Trans>
                                </LoadingButton>
                              </Grid>
                            ) : null}
                            <Grid md={2} xs={3}>
                              <LoadingButton
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="secondary"
                                sx={{textTransform: "none"}}
                                disabled={isSubmitting}
                                loading={isSubmitting}
                                loadingPosition="end"
                                endIcon={<></>}
                              >
                                <Trans i18nKey={i18nKeyPrefix + "save"}>
                                  Save
                                </Trans>
                              </LoadingButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
