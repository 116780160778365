import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Field, FormikProps} from "formik";
import {TextField} from "formik-mui";
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  GetStatesQueryValues,
  StateDto,
  StatesGetApiArg,
  useStatesGetMutation,
} from "../../../features/states/states-api";
import {DeliveryAddressFormFields, ToAddressComponentProps} from "./interfaces";
import {toastError} from "../../../common/utils/toastMessages";
import {getDeliveryAddressValidationSchema} from "../../pages/validation";
import {getLangCodeFromI18n} from "../../../app/app.component";
import MuiPhoneNumber from "material-ui-phone-number";
import {SelectedTabEnum} from "./deliveryAddressForm";
import {
  CountryFilter,
  useGetCountries,
} from "../../../common/hooks/useGetCountries";
import {useFormRequiredFields} from "../../../common/hooks/useFormRequiredFields";
import {useMapsLibrary} from "@vis.gl/react-google-maps";

const PHONE_NUMBER_REGEX = /^\+?\d*$/;

type AddressLineAutocompleteValue = {
  place_id?: string | null;
  label?: string | null;
};

export default function ToAddressComponent({
  formikProps,
  initialActiveCountry,
  disabled = false,
  isEditingAddress = false,
  setValidationSchema,
  setCurrentAddress,
  showCompanyName,
  selectedTab,
  phoneNumberDefaultCountryCode,
  requiredFields = [],
  countryFilter,
  disableCountrySelect = false,
}: ToAddressComponentProps) {
  const langCode = getLangCodeFromI18n();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isDisplaySm = useMediaQuery(theme.breakpoints.up("sm"));
  const {t} = useTranslation();

  const googlePlacesLibrary = useMapsLibrary("places");
  const autocompleteService = useMemo(
    () => googlePlacesLibrary && new googlePlacesLibrary.AutocompleteService(),
    [googlePlacesLibrary],
  ) as google.maps.places.AutocompleteService | null;
  const placesService = useMemo(
    () =>
      googlePlacesLibrary &&
      new googlePlacesLibrary.PlacesService(document.createElement("div")),
    [googlePlacesLibrary],
  ) as google.maps.places.PlacesService | null;

  const [suggestedPlacesOptions, setSuggestedPlacesOptions] = useState<any>([]);
  const [isPlacesLoading, setIsPlacesLoading] = useState<boolean>(false);

  const [isOpenAddressLineMenu, setIsOpenAddressLineMenu] =
    useState<boolean>(false);
  const [isOpenRegionsMenu, setIsOpenRegionsMenu] = useState<boolean>(false);

  const {
    data: countries,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetCountries({filter: countryFilter});

  const {data: phoneNumberCountries, isLoading: isPhoneNumberCountriesLoading} =
    useGetCountries({
      filter: CountryFilter.PhoneNumber,
    });

  const phoneNumberCountryCodes = useMemo(() => {
    return phoneNumberCountries?.map(
      (country) => country?.countryCode?.toLowerCase() ?? "",
    );
  }, [phoneNumberCountries]);

  const [
    getStates,
    {
      isLoading: isLoadingStates,
      isSuccess: isSuccessStates,
      isError: isErrorStates,
      error: errorStates,
    },
  ] = useStatesGetMutation();

  const [regions, setRegions] = useState<StateDto[]>();
  const [isCountryLoading, setIsCountryLoading] = useState<boolean>(true);
  const [isStatesLoading, setIsStatesLoading] = useState<boolean>();
  const [countryCode, setCountryCode] =
    useState<string | null>(initialActiveCountry);
  const [addressFormat, setAddressFormat] = useState<string | null>(null);
  const context = formikProps as FormikProps<DeliveryAddressFormFields>;
  const [phoneCountryCode, setPhoneCountryCode] =
    useState<string | undefined>("US");
  const [phoneInputValue, setPhoneInputValue] = useState<string | null>(
    formikProps?.values?.toAddressPhoneNumber,
  );
  const [toAddressRegionCode, setToAddressRegionCode] = useState<string | null>(
    formikProps?.values?.toAddressRegionCode,
  );

  const {renderRequiredMark} = useFormRequiredFields(requiredFields);

  const isAutofillEvent = (event: any) => {
    return !(
      event?.type === "focus" ||
      event?.type === "click" ||
      event?.type === "mousedown" ||
      event?.nativeEvent instanceof InputEvent
    );
  };

  useEffect(() => {
    if (selectedTab === SelectedTabEnum.left) {
      formikProps.setFieldValue("toAddressPhoneNumber", phoneInputValue);
      formikProps.setFieldValue("toAddressRegionCode", toAddressRegionCode);
      formikProps.setFieldValue("toAddressCountryCode", countryCode);
    }
  }, [selectedTab]);

  useEffect(() => {
    const selectedCountry = countries?.find(
      (country) => country.countryCode === countryCode,
    );
    if (selectedCountry) {
      const customAddressFormat =
        selectedCountry?.customValues?.["address-format"];
      setAddressFormat(customAddressFormat === "US" ? "US" : "NonUS");
    }
  }, [countryCode, countries]);

  useEffect(() => {
    if (typeof setValidationSchema === "function") {
      setValidationSchema(
        getDeliveryAddressValidationSchema(
          "toAddress",
          t,
          addressFormat,
          phoneCountryCode,
        ),
      );
    }
  }, [addressFormat, t, phoneCountryCode]);

  const onPhoneNumberChange = (
    event: any,
    formikProps: FormikProps<DeliveryAddressFormFields>,
  ) => {
    const newValue = event;
    if (newValue && !PHONE_NUMBER_REGEX.test(newValue)) return;
    formikProps.setFieldValue("toAddressPhoneNumber", newValue);
    setPhoneInputValue(newValue);
  };

  const getRegions = async (request: GetStatesQueryValues) => {
    const commandArgs: StatesGetApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      getStatesQueryValues: request,
    };
    return getStates(commandArgs);
  };

  const handleAddressLineChange = useCallback(
    async (
      _: React.SyntheticEvent,
      value: AddressLineAutocompleteValue | null,
    ) => {
      if (value) {
        if (value?.place_id && placesService) {
          setIsPlacesLoading(true);
          placesService.getDetails(
            {
              placeId: value.place_id,
              language: "en",
              fields: [
                "geometry.location",
                "formatted_address",
                "address_components",
              ],
            },
            (result, status) => {
              if (status == "OK" && result) {
                const toAddressLatitude = result?.geometry?.location?.lat();
                const toAddressLongitude = result?.geometry?.location?.lng();
                const toAddressStreetName =
                  [
                    result?.address_components?.find((component) =>
                      component.types.includes("street_number"),
                    )?.long_name,
                    result?.address_components?.find((component) =>
                      component.types.includes("route"),
                    )?.long_name,
                  ]
                    .filter((x) => !!x)
                    .join(" ") ?? "";
                const toAddressRegionCode =
                  result?.address_components?.find((component) =>
                    component.types.includes("administrative_area_level_1"),
                  )?.short_name ?? "";
                const toAddressPostalCode =
                  result?.address_components?.find((component) =>
                    component.types.includes("postal_code"),
                  )?.short_name ?? "";
                const toAddressCity =
                  result?.address_components?.find((component) =>
                    component.types.includes("locality"),
                  )?.long_name ?? "";

                formikProps?.setFieldValue(
                  "toAddressLatitude",
                  toAddressLatitude,
                );
                formikProps?.setFieldValue(
                  "toAddressLongitude",
                  toAddressLongitude,
                );
                formikProps?.setFieldValue(
                  "toAddressStreetName",
                  toAddressStreetName,
                );
                formikProps?.setFieldValue(
                  "toAddressRegionCode",
                  toAddressRegionCode,
                );
                formikProps?.setFieldValue(
                  "toAddressPostalCode",
                  toAddressPostalCode,
                );
                formikProps?.setFieldValue("toAddressCity", toAddressCity);

                formikProps?.validateForm({
                  ...formikProps?.values,
                  toAddressLatitude,
                  toAddressLongitude,
                  toAddressStreetName,
                  toAddressCity,
                  toAddressRegionCode,
                  toAddressPostalCode,
                });
              }
              setIsPlacesLoading(false);
            },
          );
        } else {
          formikProps?.setFieldValue("toAddressStreetName", value?.label);
        }
      }
    },
    [formikProps, placesService, setIsPlacesLoading],
  );

  const handleToAddressStreetNameChange = useCallback(
    (input: any) => {
      if (input && input.length > 1) {
        if (autocompleteService) {
          autocompleteService.getPlacePredictions(
            {
              input,
              language: "en",
              types: ["address"],
              region: formikProps?.values?.toAddressCountryCode,
            },
            (a, b) => {
              if (b == "OK" && a) {
                const mappedResult = a.map((suggestion) => {
                  return {
                    label: suggestion?.description,
                    place_id: suggestion?.place_id,
                  } as AddressLineAutocompleteValue;
                });
                const suggestedPlaces = [...mappedResult];
                if (
                  !mappedResult?.some(
                    (result: AddressLineAutocompleteValue) =>
                      result?.label == input,
                  )
                )
                  suggestedPlaces?.push({label: input, place_id: null}); // raw input value as last autocomplete option
                setSuggestedPlacesOptions(suggestedPlaces);
              } else {
                setSuggestedPlacesOptions([{label: input, place_id: null}]);
              }
            },
          );
        } else {
          setSuggestedPlacesOptions([{label: input, place_id: null}]);
        }
      }
    },
    [autocompleteService, formikProps?.values?.toAddressCountryCode],
  );

  useEffect(() => {
    handleToAddressStreetNameChange(formikProps?.values?.toAddressStreetName);
  }, [formikProps?.values?.toAddressStreetName]);

  useEffect(() => {
    if (isSuccess) {
      setIsCountryLoading(false);
    }
    if (isError) {
      setIsCountryLoading(false);
      toastError(error);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSuccessStates) {
      setIsStatesLoading(false);
    }
    if (isErrorStates) {
      setIsStatesLoading(false);
      toastError(errorStates);
    }
  }, [isLoadingStates]);

  useEffect(() => {
    if (countryCode) {
      setIsStatesLoading(true);
      const getRegionsAsync = async () => {
        const response: any = await getRegions({
          offset: 0,
          limit: 100,
          sort: "name",
          filter: `countryCode:${countryCode} AND CustomValues.state_is_active:true`,
          search: "",
        });
        setRegions(response.data?.items);
      };
      getRegionsAsync();
    }
  }, [countryCode]);

  const handleCountryChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    formikProps: FormikProps<DeliveryAddressFormFields>,
  ) => {
    const newValue = event.target.value;
    formikProps.setFieldValue("toAddressCountryCode", newValue);
    setCountryCode(newValue);
  };

  const getRegionsSelectValue = () => {
    const value =
      regions?.find(
        (region) =>
          region.stateCode === formikProps?.values.toAddressRegionCode,
      ) ?? null;
    if (!value && formikProps?.values.toAddressRegionCode && regions) {
      formikProps.setFieldValue("toAddressRegionCode", "");
    }
    return value;
  };

  return (
    <Grid
      container
      direction={"column"}
      sx={{width: "100%"}}
      rowGap={4}
      padding={2}
    >
      <Typography variant={"h3"}>
        <Trans i18nKey={"contactInfo"}>Contact Information</Trans>
      </Typography>
      <Grid>
        <Grid container columns={6} spacing={2}>
          {showCompanyName && (
            <Grid xs={12} md={12} lg={12}>
              <Field
                component={TextField}
                variant="outlined"
                fullWidth
                size={isDesktop ? "normal" : "small"}
                InputLabelProps={{shrink: true}}
                label={
                  t("companyNameLabel") +
                  renderRequiredMark("toAddressCompanyName")
                }
                name="toAddressCompanyName"
                autoComplete="organization"
                data-testid="input-company-name"
                disabled={disabled}
              />
            </Grid>
          )}
          <Grid xs={6} md={3} lg={3}>
            <Field
              name="toAddressFirstName"
              component={TextField}
              variant="outlined"
              size={isDesktop ? "normal" : "small"}
              fullWidth
              label={
                t("firstNameLabel") + renderRequiredMark("toAddressFirstName")
              }
              InputLabelProps={{shrink: true}}
              autoComplete="given-name"
              disabled={disabled}
              data-testid="input-first-name"
            />
          </Grid>
          <Grid xs={6} md={3} lg={3}>
            <Field
              name="toAddressLastName"
              component={TextField}
              variant="outlined"
              size={isDesktop ? "normal" : "small"}
              fullWidth
              label={
                t("lastNameLabel") + renderRequiredMark("toAddressLastName")
              }
              InputLabelProps={{shrink: true}}
              autoComplete="family-name"
              disabled={disabled}
              data-testid="input-last-name"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid>
        <Grid container columns={6} spacing={2}>
          <Grid xs={6} md={3} lg={3}>
            {isPhoneNumberCountriesLoading ? (
              <CircularProgress size={30} />
            ) : (
              <>
                <MuiPhoneNumber
                  sx={{"& svg": {height: "0.8em"}}}
                  disableAreaCodes={true}
                  fullWidth
                  size={isDesktop ? "medium" : "small"}
                  id="toAddressPhoneNumber"
                  label={
                    t("phoneNumber") +
                    renderRequiredMark("toAddressPhoneNumber")
                  }
                  name="toAddressPhoneNumber"
                  autoComplete="phoneNumber"
                  variant="outlined"
                  defaultCountry={phoneNumberDefaultCountryCode ?? "us"}
                  excludeCountries={["ru"]}
                  onlyCountries={phoneNumberCountryCodes}
                  value={context?.values?.toAddressPhoneNumber}
                  countryCodeEditable={true}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  onChange={(e: any, countryData: any) => {
                    setPhoneCountryCode(
                      countryData?.countryCode?.toUpperCase(),
                    );
                    onPhoneNumberChange(
                      e
                        .replace(/\(/g, "")
                        .replace(/\)/g, "")
                        .replace(/-/g, "")
                        .replace(/ /g, ""),
                      context,
                    );
                  }}
                  error={Boolean(context?.errors.toAddressPhoneNumber)}
                />
                <FormHelperText sx={{color: "#d32f2f", mt: 1, ml: 2}}>
                  {context?.errors.toAddressPhoneNumber
                    ? context?.errors.toAddressPhoneNumber
                    : ""}
                </FormHelperText>
              </>
            )}
          </Grid>
          <Grid xs={6} md={3} lg={3}>
            <Field
              component={TextField}
              variant="outlined"
              fullWidth
              size={isDesktop ? "normal" : "small"}
              InputLabelProps={{shrink: true}}
              label={t("emailLabel") + renderRequiredMark("toAddressEmail")}
              name="toAddressEmail"
              autoComplete="email"
              data-testid="input-email"
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid style={{height: "25px"}}>
        <Grid container columns={6} rowSpacing={2}>
          <Grid
            xs={3}
            md={3}
            lg={3}
            sx={{display: "flex", alignItems: "center"}}
          >
            <Typography variant={"h3"}>
              <Trans i18nKey={"addressDetails"}>Address details</Trans>
            </Typography>
          </Grid>
          <Grid
            xs={3}
            md={3}
            lg={3}
            sx={{display: "flex", alignItems: "center", justifyContent: "end"}}
          >
            {setCurrentAddress && (
              <Typography
                variant={"body1"}
                color={"#3E80FF"}
                style={{cursor: "pointer", userSelect: "none"}}
                onClick={() => setCurrentAddress(null)}
              >
                <u>
                  <Trans i18nKey={"selectAddress"}>
                    Choose one of my addresses
                  </Trans>
                </u>
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Grid container columns={6} spacing={2}>
          <Grid xs={6} md={6} lg={6}>
            <FormControl sx={{width: "100%"}}>
              <Field
                component={TextField}
                InputLabelProps={{shrink: true}}
                select
                variant="outlined"
                size={isDesktop ? "normal" : "small"}
                label={
                  t("countryLabel") + renderRequiredMark("toAddressCountryCode")
                }
                name="toAddressCountryCode"
                data-testid="select-country"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleCountryChange(
                    event,
                    formikProps as FormikProps<DeliveryAddressFormFields>,
                  )
                }
                disabled={disabled || disableCountrySelect}
                startAdornment={
                  isCountryLoading && (
                    <InputAdornment position="start">
                      <CircularProgress size={20} />
                    </InputAdornment>
                  )
                }
              >
                {isCountryLoading ? (
                  <Grid sx={{display: "flex", justifyContent: "center"}}>
                    <CircularProgress size={20} />
                  </Grid>
                ) : (
                  countries?.map((country) => (
                    <MenuItem
                      key={country.countryCode ?? ""}
                      value={country.countryCode ?? ""}
                      data-testid={`option-${country.countryCode}`}
                    >
                      {country.customValues &&
                      `country_translation_${langCode}` in country.customValues
                        ? country.customValues[
                            `country_translation_${langCode}`
                          ]
                        : country.name}
                    </MenuItem>
                  ))
                )}
              </Field>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      {addressFormat === "NonUS" ? (
        <>
          <Grid>
            <Grid
              container
              columns={6}
              spacing={{xs: 2, md: isEditingAddress ? 0 : 2}}
            >
              <Grid
                xs={6}
                md={isEditingAddress ? 6 : 3}
                lg={isEditingAddress ? 6 : 3}
              >
                <Autocomplete
                  options={regions ?? []}
                  autoComplete={true}
                  autoHighlight={true}
                  autoSelect={true}
                  clearOnEscape={true}
                  getOptionLabel={(option: any) => {
                    return option.customValues &&
                      `state_${langCode}` in option.customValues
                      ? option.customValues[`state_${langCode}`]
                      : option.name;
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.stateCode === value.stateCode
                  }
                  disabled={!countryCode || disabled}
                  value={getRegionsSelectValue()}
                  renderInput={(params: any) => (
                    <Field
                      {...params}
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      size={isDesktop ? "medium" : "small"}
                      label={
                        t("regionLabel") +
                        renderRequiredMark("toAddressRegionCode")
                      }
                      data-testid="input-region"
                      name="toAddressRegionCode"
                      sx={{
                        "& .MuiAutocomplete-clearIndicator": {
                          display: "none",
                          pointerEvents: "none",
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: isStatesLoading && (
                          <InputAdornment position="start">
                            <CircularProgress size={20} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  onChange={(event: any, value: StateDto | null) => {
                    formikProps?.setFieldValue(
                      "toAddressRegionCode",
                      value?.stateCode,
                    );
                    setToAddressRegionCode(value?.stateCode || null);
                  }}
                />
              </Grid>
              <Grid xs={6} md={3} lg={3}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  InputLabelProps={{shrink: true}}
                  label={
                    t("districtLabel") + renderRequiredMark("toAddressDistrict")
                  }
                  name="toAddressDistrict"
                  autoComplete="district"
                  data-testid="input-district"
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid>
            <Grid container columns={6} spacing={2}>
              <Grid xs={6} md={3} lg={3}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  InputLabelProps={{shrink: true}}
                  label={t("cityLabel") + renderRequiredMark("toAddressCity")}
                  name="toAddressCity"
                  autoComplete="city"
                  data-testid="input-city"
                  disabled={disabled}
                />
              </Grid>
              <Grid xs={6} md={3} lg={3}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  InputLabelProps={{shrink: true}}
                  label={
                    t("postalCodeLabel") +
                    renderRequiredMark("toAddressPostalCode")
                  }
                  name="toAddressPostalCode"
                  autoComplete="postal-code"
                  data-testid="input-postal-code"
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid>
            <Grid container columns={6} spacing={2}>
              <Grid xs={6} md={6} lg={6}>
                <Field
                  component={TextField}
                  variant={"outlined"}
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  InputLabelProps={{shrink: true}}
                  label={
                    (isEditingAddress
                      ? t("addressLine")
                      : t("streetNameLabel")) +
                    renderRequiredMark("toAddressStreetName")
                  }
                  name={"toAddressStreetName"}
                  autoComplete={"street-name"}
                  data-testid={"input-street-name"}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid>
            <Grid container columns={6} spacing={isEditingAddress ? 0 : 2}>
              <Grid xs={6} md={3} lg={3}>
                {!isEditingAddress ? (
                  <Field
                    component={TextField}
                    variant="outlined"
                    fullWidth
                    size={isDesktop ? "normal" : "small"}
                    InputLabelProps={{shrink: true}}
                    label={
                      t("houseNumberLabel") +
                      renderRequiredMark("toAddressHouseNumber")
                    }
                    name="toAddressHouseNumber"
                    autoComplete="house-number"
                    data-testid="input-house-number"
                    disabled={disabled}
                  />
                ) : null}
              </Grid>
              <Grid
                xs={6}
                md={isEditingAddress ? 6 : 3}
                lg={isEditingAddress ? 6 : 3}
              >
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  InputLabelProps={{shrink: true}}
                  label={
                    (isEditingAddress
                      ? t("addressLine2")
                      : t("apartmentLabel")) +
                    renderRequiredMark("toAddressApartment")
                  }
                  name="toAddressApartment"
                  autoComplete="apartment"
                  data-testid="input-apertment"
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : addressFormat === "US" ? (
        <>
          <Grid>
            <Grid container columns={6} spacing={2}>
              <Grid xs={6} md={6} lg={6}>
                <Autocomplete
                  options={suggestedPlacesOptions}
                  open={isOpenAddressLineMenu}
                  onOpen={(event: any) => {
                    if (!isAutofillEvent(event)) {
                      setIsOpenAddressLineMenu(true);
                    }
                  }}
                  onClose={() => setIsOpenAddressLineMenu(false)}
                  autoComplete={true}
                  autoHighlight={true}
                  clearOnEscape={true}
                  getOptionLabel={(option: AddressLineAutocompleteValue) =>
                    option?.label ?? ""
                  }
                  isOptionEqualToValue={(option) =>
                    option === suggestedPlacesOptions?.[0]
                  }
                  filterOptions={(options) => options}
                  disabled={disabled}
                  value={
                    formikProps?.values?.toAddressStreetName
                      ? {
                          label: formikProps?.values?.toAddressStreetName,
                          place_id: null,
                        }
                      : {}
                  }
                  onChange={handleAddressLineChange}
                  renderInput={(params: any) => (
                    <Field
                      {...params}
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      size={isDisplaySm ? "normal" : "small"}
                      label={
                        t("addressLine") +
                        renderRequiredMark("toAddressStreetName")
                      }
                      data-testid="input-region"
                      name="toAddressStreetName"
                      sx={{
                        "& .MuiAutocomplete-clearIndicator": {
                          display: "none",
                          pointerEvents: "none",
                        },
                      }}
                      onChange={(event: any) => {
                        formikProps?.setFieldValue(
                          "toAddressStreetName",
                          event?.target?.value,
                        );
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: isPlacesLoading && (
                          <InputAdornment position="start">
                            <CircularProgress size={20} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container columns={6} spacing={2}>
            <Grid xs={6} md={6} lg={6}>
              <Field
                component={TextField}
                variant={"outlined"}
                fullWidth
                size={isDesktop ? "normal" : "small"}
                InputLabelProps={{shrink: true}}
                label={
                  t("addressLine2Label") +
                  renderRequiredMark("toAddressApartment")
                }
                name={"toAddressApartment"}
                autoComplete={"street-name"}
                data-testid={"input-street-name"}
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Grid container columns={6} spacing={2}>
            <Grid xs={6} md={6} lg={6}>
              <Field
                component={TextField}
                variant="outlined"
                fullWidth
                size={isDesktop ? "normal" : "small"}
                InputLabelProps={{shrink: true}}
                label={t("cityLabel") + renderRequiredMark("toAddressCity")}
                name="toAddressCity"
                autoComplete="city"
                data-testid="input-city"
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Grid>
            <Grid container columns={6} spacing={2}>
              <Grid xs={6} md={3} lg={3}>
                <Autocomplete
                  options={regions ?? []}
                  open={isOpenRegionsMenu}
                  onOpen={(event) => {
                    if (!isAutofillEvent(event)) {
                      setIsOpenRegionsMenu(true);
                    }
                  }}
                  onClose={() => setIsOpenRegionsMenu(false)}
                  autoComplete={true}
                  autoHighlight={true}
                  autoSelect={true}
                  clearOnEscape={true}
                  getOptionLabel={(option: any) => option.name ?? ""}
                  isOptionEqualToValue={(option, value) =>
                    option.stateCode === value.stateCode
                  }
                  disabled={!countryCode || disabled}
                  value={getRegionsSelectValue()}
                  renderInput={(params: any) => (
                    <Field
                      {...params}
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      size={isDisplaySm ? "normal" : "small"}
                      label={
                        t("stateLabel") +
                        renderRequiredMark("toAddressRegionCode")
                      }
                      data-testid="input-region"
                      name="toAddressRegionCode"
                      sx={{
                        "& .MuiAutocomplete-clearIndicator": {
                          display: "none",
                          pointerEvents: "none",
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: isStatesLoading && (
                          <InputAdornment position="start">
                            <CircularProgress size={20} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  onChange={(event: any, value: StateDto | null) => {
                    formikProps?.setFieldValue(
                      "toAddressRegionCode",
                      value?.stateCode,
                    );
                    setToAddressRegionCode(value?.stateCode || null);
                  }}
                />
              </Grid>
              <Grid xs={6} md={3} lg={3}>
                <Field
                  component={TextField}
                  variant="outlined"
                  fullWidth
                  size={isDesktop ? "normal" : "small"}
                  InputLabelProps={{shrink: true}}
                  label={
                    t("postalCodeLabel") +
                    renderRequiredMark("toAddressPostalCode")
                  }
                  name="toAddressPostalCode"
                  autoComplete="postal-code"
                  data-testid="input-postal-code"
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
}
