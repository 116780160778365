import React, {useEffect, useMemo, useState} from "react";
import {toast} from "react-hot-toast";
import {Trans, useTranslation} from "react-i18next";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Radio,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import Grid from "@mui/material/Unstable_Grid2";
import {VerificationStepSubmit} from "./interfaces";
import {LoadingButton} from "@mui/lab";
import {RadioGroup, TextField} from "formik-mui";
import {Field, Form, Formik} from "formik";
import {getCodeSchema, getPhoneSchema} from "../pages/validation";
import {
  SendPhoneCodeCommand,
  useVerificationPhoneCodeMutation,
  useVerificationVerifyCodeMutation,
  VerificationPhoneCodeApiArg,
  VerificationVerifyCodeApiArg,
  VerifyPhoneCodeCommand,
} from "../../features/verification/verification-api";
import {SubmitHandler} from "react-hook-form";
import {useAppSelector} from "../../store";
import {useDispatch} from "react-redux";
import {
  setPhoneNumber as setPhoneNumberAction,
  verifyPhoneNumber,
} from "../../features/verification/verification-slice";
import {
  CountryFilter,
  useGetCountries,
} from "../../common/hooks/useGetCountries";

export default function PhoneVerificationFragment({
  handleNext,
  step,
}: VerificationStepSubmit) {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [displayVerify, setDisplayVerify] = useState("none");
  const [hideGettingCode, setHideGettingCode] = useState("flex");
  const [phoneNumber, setPhoneNumber] = useState<any>(null);
  const [notificationStatus, setNotificationStatus] = useState<any>(null);
  const [verificationStatus, setVerificationStatus] = useState<any>(null);

  const initialValuesSend: SendPhoneCodeCommand = {
    phoneNumber: useAppSelector(
      (state) => state.verificationState?.phoneNumber?.value?.phoneNumber || "",
    ),
    sendingType: useAppSelector(
      (state) => state.verificationState?.phoneNumber?.value?.sendingType || "",
    ),
  };

  const initialValuesVerify: VerifyPhoneCodeCommand = {
    username: useAppSelector((state) => state.userState.email),
    verificationCode: "",
    phoneNumber: "",
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [sendCode, {isLoading, isError, isSuccess}] =
    useVerificationPhoneCodeMutation();

  useEffect(() => {
    if (isSuccess) {
      setNotificationStatus(true);
    }
    if (isError) {
      setNotificationStatus(false);
    }
  }, [isLoading]);

  const [
    verifyCode,
    {
      isLoading: isLoadingVerify,
      isError: isErrorVerify,
      isSuccess: isSuccessVerify,
    },
  ] = useVerificationVerifyCodeMutation();

  const {data: phoneNumberCountries, isLoading: isPhoneNumberCountriesLoading} =
    useGetCountries({
      filter: CountryFilter.PhoneNumber,
    });

  const phoneNumberCountryCodes = useMemo(() => {
    return phoneNumberCountries?.map(
      (country) => country?.countryCode?.toLowerCase() ?? "",
    );
  }, [phoneNumberCountries]);

  useEffect(() => {
    if (isSuccessVerify) {
      dispatch(verifyPhoneNumber(initialValuesSend));
      toast.success(`${t("toasts.phoneVerificationSuccess")}`);
      handleNext();
    }
    if (isErrorVerify) {
      setVerificationStatus(false);
    }
  }, [isLoadingVerify]);

  const onSubmit: SubmitHandler<SendPhoneCodeCommand> = (values) => {
    const commandArgs: VerificationPhoneCodeApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      sendPhoneCodeCommand: values,
    };
    setNotificationStatus(null);
    sendCode(commandArgs);
    setPhoneNumber(commandArgs.sendPhoneCodeCommand.phoneNumber);
  };

  const onVerify: SubmitHandler<VerifyPhoneCodeCommand> = (values) => {
    const commandArgs: VerificationVerifyCodeApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      verifyPhoneCodeCommand: values,
    };
    setVerificationStatus(null);
    commandArgs.verifyPhoneCodeCommand.phoneNumber = phoneNumber;
    verifyCode(commandArgs);
  };

  useEffect(() => {
    if (notificationStatus) {
      setDisplayVerify("flex");
      setHideGettingCode("none");
    } else {
      setDisplayVerify("none");
      setHideGettingCode("flex");
    }
  }, [notificationStatus]);

  const phoneCallRadioLabel = (
    <Typography fontSize={"14px"}>
      <Trans i18nKey="phoneCall">Phone call</Trans>
    </Typography>
  );

  const textMessageRadioLabel = (
    <Typography fontSize={"14px"}>
      <Trans i18nKey="sms">Text message</Trans>
    </Typography>
  );

  return (
    <Grid
      container
      justifyContent="center"
      columns={{xs: 6, md: 6}}
      columnSpacing={2}
    >
      <Grid xs={6} md={6}>
        <Grid container direction="column">
          <Grid>
            <Grid container columns={{xs: 6, md: 6}}>
              <Grid xs={6} md={5}>
                <Typography variant="h3" component="h3" mb={3}>
                  <Trans i18nKey="phoneVerificationSubtitle">
                    Fill out your phone number. <br /> Receive and enter the
                    6-digit <br />
                    verification code by accepting a call
                  </Trans>
                </Typography>
              </Grid>
              <Grid xs={6} md={5}>
                <Grid container direction="column" columns={{xs: 6, md: 5}}>
                  <Formik
                    onSubmit={(values: SendPhoneCodeCommand) => {
                      onSubmit(values);
                    }}
                    initialValues={initialValuesSend}
                    validationSchema={getPhoneSchema(t)}
                  >
                    {({isSubmitting, errors, values, touched}) => (
                      <Form id="sendLinkForm">
                        <Grid md={5}>
                          <Grid
                            container
                            direction="column"
                            columns={{xs: 6, md: 5}}
                          >
                            <Grid md={5}>
                              <Field
                                component={RadioGroup}
                                row
                                id="sendingType"
                                name="sendingType"
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  values.sendingType = e.target.value;
                                  dispatch(setPhoneNumberAction(values));
                                }}
                              >
                                <FormControlLabel
                                  value="call"
                                  control={<Radio size="small" />}
                                  label={phoneCallRadioLabel}
                                />
                                <FormControlLabel
                                  value="sms"
                                  control={<Radio size="small" />}
                                  label={textMessageRadioLabel}
                                />
                              </Field>
                            </Grid>
                            <FormHelperText
                              sx={{color: "#d32f2f", ml: 3, mb: 2}}
                            >
                              {errors.sendingType ? errors.sendingType : ""}
                            </FormHelperText>
                            <Grid md={3} xs={6}>
                              {isPhoneNumberCountriesLoading ? (
                                <CircularProgress size={30} />
                              ) : (
                                <>
                                  <MuiPhoneNumber
                                    sx={{"& svg": {height: "0.8em"}}}
                                    disableAreaCodes={true}
                                    fullWidth
                                    size={isDesktop ? "medium" : "small"}
                                    id="phoneNumber"
                                    label={t("phoneNumber")}
                                    name="phoneNumber"
                                    autoComplete="phoneNumber"
                                    variant="outlined"
                                    defaultCountry={"us"}
                                    excludeCountries={["ru"]}
                                    onlyCountries={phoneNumberCountryCodes}
                                    value={useAppSelector(
                                      (state) =>
                                        state.verificationState?.phoneNumber
                                          ?.value?.phoneNumber ?? "+1",
                                    )}
                                    onChange={(e: any) => {
                                      values.phoneNumber = e
                                        .replace(/\(/g, "")
                                        .replace(/\)/g, "")
                                        .replace(/-/g, "")
                                        .replace(/ /g, "");
                                      dispatch(setPhoneNumberAction(values));
                                    }}
                                    disabled={
                                      (isSubmitting &&
                                        notificationStatus == null) ||
                                      (isSubmitting && notificationStatus)
                                    }
                                    error={Boolean(
                                      touched.phoneNumber && errors.phoneNumber,
                                    )}
                                  />
                                  <FormHelperText
                                    sx={{color: "#d32f2f", mt: 1, ml: 2}}
                                  >
                                    {touched.phoneNumber && errors.phoneNumber
                                      ? errors.phoneNumber
                                      : ""}
                                  </FormHelperText>
                                  {notificationStatus === true && (
                                    <Typography
                                      variant="subtitle2"
                                      sx={{
                                        color: "primary.dark",
                                        fontSize: "16px",
                                      }}
                                    >
                                      <Button
                                        type="button"
                                        form="sendLinkForm"
                                        disableElevation
                                        disableRipple
                                        onClick={() =>
                                          setNotificationStatus(false)
                                        }
                                        sx={{
                                          textTransform: "none",
                                          height: {xs: "14px", md: "14px"},
                                          p: 0,
                                          fontWeight: "bold",
                                          "&.MuiButtonBase-root:hover": {
                                            bgcolor: "transparent",
                                          },
                                        }}
                                      >
                                        <Trans i18nKey="phoneVerification.changePhoneNumber">
                                          Change phone number
                                        </Trans>
                                      </Button>
                                    </Typography>
                                  )}
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          xs={6}
                          md={6}
                          sx={{
                            position: {xs: "fixed", md: "inherit"},
                            bottom: {xs: "72px"},
                            pr: {xs: "26px", md: 0},
                            mt: {xs: 0, md: 1},
                          }}
                        >
                          <Grid
                            container
                            direction="row"
                            columns={{xs: 6, md: 6}}
                            display={{xs: `${hideGettingCode}`, md: "flex"}}
                          >
                            <Grid md={2} xs={3} display={{md: "none"}}>
                              <Button
                                fullWidth
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={handleNext}
                              >
                                <Trans i18nKey="skip">Skip</Trans>
                              </Button>
                            </Grid>
                            <Grid md={2} xs={3}>
                              <LoadingButton
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="secondary"
                                sx={{textTransform: "none"}}
                                disabled={isSubmitting && notificationStatus}
                                loading={
                                  isSubmitting && notificationStatus == null
                                }
                                loadingPosition="end"
                                endIcon={<></>}
                              >
                                <Trans i18nKey="getCode">Get code</Trans>
                              </LoadingButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
              <Grid xs={6} md={6} mt={5}>
                {notificationStatus === true && (
                  <Typography
                    variant="body4"
                    sx={{
                      color: "primary.dark",
                      mb: 2,
                    }}
                  >
                    <Trans i18nKey="codeWasSent">
                      Verification code was sent to your number.
                      <br />
                      Did not receive a code?
                    </Trans>
                    &nbsp;
                    <Button variant="link" type="submit" form="sendLinkForm">
                      <Trans i18nKey="clickHere">Click here</Trans>
                    </Button>
                    &nbsp;
                    <Trans i18nKey="toResendIt">to resend it</Trans>
                  </Typography>
                )}
                <Formik
                  onSubmit={(values: VerifyPhoneCodeCommand) => {
                    onVerify(values);
                  }}
                  initialValues={initialValuesVerify}
                  validationSchema={getCodeSchema(t)}
                >
                  {({isSubmitting}) => (
                    <Form>
                      <Grid
                        container
                        direction="column"
                        columns={{xs: 6, md: 6}}
                        display={{xs: `${displayVerify}`, md: "flex"}}
                      >
                        <Grid md={2} mb={5}>
                          <Field
                            fullWidth
                            size={isDesktop ? "normal" : "small"}
                            id="verificationCode"
                            label={t("verificationCode")}
                            placeholder="000000"
                            name="verificationCode"
                            autoComplete="verificationCode"
                            component={TextField}
                            inputProps={{maxLength: 6}}
                            disabled={
                              (isSubmitting && verificationStatus == null) ||
                              (isSubmitting && verificationStatus) ||
                              !notificationStatus
                            }
                          />
                        </Grid>
                        <Grid xs={6} md={6}>
                          <Grid
                            container
                            direction="row"
                            columns={{xs: 6, md: 6}}
                          >
                            {!step.required ? (
                              <Grid md={2} xs={3}>
                                <Button
                                  fullWidth
                                  type="button"
                                  variant="outlined"
                                  color="primary"
                                  onClick={handleNext}
                                >
                                  <Trans i18nKey="skip">Skip</Trans>
                                </Button>
                              </Grid>
                            ) : null}
                            <Grid md={2} xs={3}>
                              <LoadingButton
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="secondary"
                                disabled={
                                  (isSubmitting && verificationStatus) ||
                                  !notificationStatus
                                }
                                loading={
                                  isSubmitting && verificationStatus == null
                                }
                                loadingPosition="end"
                                endIcon={<></>}
                              >
                                <Trans i18nKey="verify">Verify</Trans>
                              </LoadingButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
