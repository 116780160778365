import {ContactAddressDto} from "../../../features/contactAddress/contactAddress-api";
import {
  ContactAddressGqlDto,
  OrderStatuses,
} from "../../../features/order/order-api";
import addressFormatter from "@fragaria/address-formatter";

const SHIPMENT_STATUS_COLOR_WAITING_FOR_PAYMENT = "#FFCD29";
const SHIPMENT_STATUS_COLOR_IN_TRANSIT = "#ED6C02";
const SHIPMENT_STATUS_COLOR_DELIVERED = "#00BD75";
const SHIPMENT_STATUS_COLOR_ERROR = "#F02B00";

export const getShipmentStatusColor = (
  shipmentStatus: OrderStatuses | string | undefined,
): string => {
  switch (shipmentStatus) {
    case "In transit":
      return SHIPMENT_STATUS_COLOR_IN_TRANSIT;
    case "Invoiced":
      return SHIPMENT_STATUS_COLOR_IN_TRANSIT;
    case "OnRoute":
      return SHIPMENT_STATUS_COLOR_IN_TRANSIT;
    case "InYard":
      return SHIPMENT_STATUS_COLOR_IN_TRANSIT;
    case "UnLoading":
      return SHIPMENT_STATUS_COLOR_IN_TRANSIT;
    case "Delivered":
      return SHIPMENT_STATUS_COLOR_DELIVERED;
    case "Completed":
      return SHIPMENT_STATUS_COLOR_DELIVERED;
    case "Cancelled":
      return SHIPMENT_STATUS_COLOR_ERROR;
    case "Refused":
      return SHIPMENT_STATUS_COLOR_ERROR;
    case "Pending":
      return SHIPMENT_STATUS_COLOR_WAITING_FOR_PAYMENT;
    case "Waiting for payment":
      return SHIPMENT_STATUS_COLOR_WAITING_FOR_PAYMENT;
    case "Loading":
      return SHIPMENT_STATUS_COLOR_WAITING_FOR_PAYMENT;
    default:
      return SHIPMENT_STATUS_COLOR_WAITING_FOR_PAYMENT;
  }
};

export const getLocalizedGqlToponyms = (
  addressDto: ContactAddressGqlDto,
  lang: string,
) => {
  if (!addressDto) return [];

  const localizedCountryName =
    addressDto.country?.customValues &&
    `country_translation_${lang}` in addressDto.country.customValues
      ? addressDto.country.customValues.find(
          (x) => x.key == `country_translation_${lang}`,
        )?.value
      : addressDto.country?.name;
  const localizedStateName =
    addressDto.state?.customValues &&
    `state_${lang}` in addressDto.state.customValues
      ? addressDto.state.customValues?.find((x) => x.key == `state_${lang}`)
          ?.value
      : addressDto.state?.name;
  const localizedCityName =
    addressDto.city?.customValues &&
    `city_${lang}` in addressDto.city.customValues
      ? addressDto.city.customValues?.find((x) => x.key == `city_${lang}`)
          ?.value
      : addressDto.cityName;
  const localizedAddressName =
    addressDto.customValues && `address_${lang}` in addressDto.customValues
      ? addressDto.customValues?.find((x) => x.key == `address_${lang}`)?.value
      : addressDto.addressLine;

  return [
    localizedCountryName,
    localizedStateName,
    localizedCityName,
    localizedAddressName,
  ];
};

export const getLocalizedToponyms = (
  addressDto: ContactAddressDto,
  lang: string,
) => {
  if (!addressDto) return [];

  const localizedCountryName =
    addressDto.country?.customValues &&
    `country_translation_${lang}` in addressDto.country.customValues
      ? addressDto.country.customValues[`country_translation_${lang}`]
      : addressDto.countryName
      ? addressDto.countryName
      : addressDto.country?.name;
  const localizedStateName =
    addressDto.state?.customValues &&
    `state_${lang}` in addressDto.state.customValues
      ? addressDto.state.customValues[`state_${lang}`]
      : addressDto.stateName
      ? addressDto.stateName
      : addressDto.state?.name;
  const localizedCityName =
    addressDto.city?.customValues &&
    `city_${lang}` in addressDto.city.customValues
      ? addressDto.city.customValues[`city_${lang}`]
      : addressDto.cityName;
  const localizedAddressName =
    addressDto.customValues && `address_${lang}` in addressDto.customValues
      ? addressDto.customValues[`address_${lang}`]
      : addressDto.addressLine;

  return [
    localizedCountryName,
    localizedStateName,
    localizedCityName,
    localizedAddressName,
  ];
};

// Returns consignee location in format `City Name, State Code`
export const getConsigneeGqlLocation = async (
  addressDto: ContactAddressGqlDto | null | undefined,
  lang: string,
  t: any,
) => {
  if (!addressDto) return null;
  const isPickupLocation =
    addressDto.customValues &&
    `is_pickup_location` in addressDto.customValues &&
    addressDto.customValues?.find((x) => x.key == "is_pickup_location")
      ?.value === "true";

  const [
    localizedCountryName,
    localizedStateName,
    localizedCityName,
    localizedAddressName,
  ] = getLocalizedGqlToponyms(addressDto, lang);

  let addressLine2Segment = "";
  if (addressDto.addressLine2) {
    if (!isPickupLocation) {
      addressLine2Segment = `, ${t("apt")}`;
    }
    addressLine2Segment = `${addressLine2Segment} ${addressDto.addressLine2}`;
  }

  const houseNumberSegment = addressDto.customValues?.find(
    (x) => x.key == "toAddressHouseNumber",
  )?.value
    ? `${
        addressDto.customValues?.find((x) => x.key == "toAddressHouseNumber")
          ?.value
      }`
    : "";

  const result = addressFormatter.format(
    {
      road: localizedAddressName ?? "",
      houseNumber: houseNumberSegment + addressLine2Segment,
      country: localizedCountryName ?? "",
      stateDistrict: localizedStateName ?? "",
      state: localizedStateName ?? "",
      city: localizedCityName ?? "",
      postcode: addressDto.postalCode ?? "",
    },
    {
      countryCode: addressDto?.countryCode ?? "",
      appendCountry: false,
      fallbackCountryCode: "US",
      output: "array",
    },
  );

  return result;
};

// Returns consignee location in format `City Name, State Code`
export const getConsigneeLocation = async (
  addressDto: ContactAddressDto,
  lang: string,
  t: any,
) => {
  if (!addressDto) return [];

  const isPickupLocation =
    addressDto.customValues?.is_pickup_location === "true";

  const [
    localizedCountryName,
    localizedStateName,
    localizedCityName,
    localizedAddressName,
  ] = getLocalizedToponyms(addressDto, lang);

  let addressLine2Segment = "";
  if (addressDto.addressLine2) {
    if (!isPickupLocation) {
      addressLine2Segment = `, ${t("apt")}`;
    }
    addressLine2Segment = `${addressLine2Segment} ${addressDto.addressLine2}`;
  }

  const houseNumberSegment = addressDto.customValues?.toAddressHouseNumber
    ? `${addressDto.customValues?.toAddressHouseNumber}`
    : "";

  const result = addressFormatter.format(
    {
      road: localizedAddressName ?? "",
      houseNumber: houseNumberSegment + addressLine2Segment,
      country: localizedCountryName ?? "",
      stateDistrict: localizedStateName ?? "",
      state: localizedStateName ?? "",
      city: localizedCityName ?? "",
      postcode: addressDto.postalCode ?? "",
    },
    {
      countryCode: addressDto?.countryCode ?? "",
      appendCountry: false,
      fallbackCountryCode: "US",
      output: "array",
    },
  );

  return result;
};
