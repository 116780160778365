import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../../features/workflowTriggers/workflowExecution-api";
import {useCallback} from "react";
import {useAppSelector} from "../../../store";

export function useUpdateCustomerNote() {
  const [runWorkflow] = useWorkflowExecutionMutation();

  const updateCustomerNoteWorkflowId = useAppSelector(
    (state) =>
      state?.organizationConfigState?.workflows?.updateCustomerNoteWorkflowId,
  );

  const updateCustomerNote = useCallback(
    async (shipmentId: number, note: string) => {
      const workflowVariables: any = {
        orderId: shipmentId,
        customerNotes: note,
      };

      const executeQuoteWorkflowApiArgs: WorkflowExecutionApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        workflowId: updateCustomerNoteWorkflowId,
        values: {variables: {...workflowVariables}},
      };

      return await runWorkflow(executeQuoteWorkflowApiArgs);
    },
    [runWorkflow],
  );

  const config = useAppSelector(
    (state: any) => state.organizationConfigState.modules.parcelTracking,
  );

  const isNoteEditAllowed = useCallback(
    (parcelStatusName: string | null | undefined) => {
      return !!(
        config?.allowEditCustomerNoteParcelStatuses &&
        parcelStatusName &&
        config.allowEditCustomerNoteParcelStatuses.includes(parcelStatusName)
      );
    },
    [config],
  );

  return {
    updateCustomerNote,
    isNoteEditAllowed,
  };
}
