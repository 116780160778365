import React, {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import Grid from "@mui/material/Unstable_Grid2";
import PurchaseDetails from "./purchaseDetails";
import {Trans} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {CircularProgress, useMediaQuery, useTheme} from "@mui/material";
import {EditPurchasesFormProps} from "../pages/interfaces";
import {
  CommodityCustomValues,
  CommodityDto,
  CreateCommodityPortalCommand,
  CreateOrderEntityCommandValues,
  CreateOrderPortalCommand,
  EntityTypes,
  OrderGetOrderApiArg,
  OrderTypes,
  useOrderGetOrderMutation,
} from "../../../features/order/order-api";
import toast from "react-hot-toast";
import {t} from "i18next";
import {Form, Formik} from "formik";
import {getPurchaseSchema} from "../pages/validation";
import {usePurchaseDetailsSubmit} from "../hooks/UsePurchaseDetailsSubmit";
import {usePurchaseUpdate} from "../hooks/usePurchaseUpdate";
import dayjs from "dayjs";
import {useAppSelector} from "../../../store";
import {useGoogleAnalytics} from "../../../common/GoogleAnalitics/googleAnalitics.context";
import PurchaseItemsList from "./purchaseItemsList";
import {toastError} from "../../../common/utils/toastMessages";
import {useDispatch} from "react-redux";
import {
  deleteOrder,
  OrderStepData,
} from "../../../features/order/parcelShipment-slice";

type OrderEntityGenerator = (
  entityType: EntityTypes,
  nonContactName?: string | null,
  orderEntityId?: number | null,
) => CreateOrderEntityCommandValues;

const generateOrderEntity: OrderEntityGenerator = (
  entityType,
  nonContactName = "",
  orderEntityId = 0,
) => {
  return {
    orderEntitySequence: 0,
    entityType,
    customValues: {},
    nonContactName,
    orderEntityId,
  };
};

export default function EditPurchasesForm({
  showItemsMobile,
  setShowItemsMobile,
}: EditPurchasesFormProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const urlSearchParams = new URLSearchParams(useLocation().search);

  const defaultValues: CreateOrderPortalCommand = {
    trackingNumber:
      Object.fromEntries(urlSearchParams.entries()).tracknum ?? "",
    orderType: OrderTypes.purchase,
    commodities: [],
    orderEntities: [],
    vendor: generateOrderEntity(EntityTypes.Vendor),
    carrier: generateOrderEntity(EntityTypes.Carrier),
  };

  const [isLoadingPurchase, setIsLoadingPurchase] = useState<boolean>(false);

  const params = useParams();
  const orderId = Number(params.purchaseId);
  const isCreatingMode = orderId === 0;

  const userContactId = useAppSelector(
    (state: any) => state.userState.contactId,
  );
  const parcelShipmentState = useAppSelector(
    (state: any) => state.parcelShipmentState,
  );

  const [initialValues, setInitialValues] =
    useState<CreateOrderPortalCommand>(defaultValues);
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  //store order entity id to prevent losing vendor
  const [vendorEntityId, setVendorEntityId] = useState(0);

  const handleNext = () => {
    setShowItemsMobile(true);
    window.scrollTo(0, 0);
  };

  const [getOrder] = useOrderGetOrderMutation();

  const getPurchase = async (request: number) => {
    const commandArgs: OrderGetOrderApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      divisionId: process.env.REACT_APP_PORTAL_DIVISION_ID as unknown as number,
      orderId: request,
    };
    return getOrder(commandArgs);
  };

  const clearAffectedShipRequestsFromState = (orderId: number) => {
    dispatch(deleteOrder({orderIds: [orderId]}));
    parcelShipmentState?.ordersData?.forEach((shipRequest: OrderStepData) => {
      if (shipRequest.orderIds && shipRequest.orderIds.includes(orderId)) {
        dispatch(deleteOrder({orderIds: shipRequest?.orderIds}));
      }
    });
  };

  const {onSubmit, isLoading, isSuccess, isError} = usePurchaseDetailsSubmit();

  const {onUpdate, isUpdateProcess, isUpdateSuccess, isUpdateError} =
    usePurchaseUpdate(orderId);

  const [isRequestToShip, setIsRequestToShip] = useState(false);

  const [createdOrderId, setCreatedOrderId] = useState(0);

  const {sendEvent} = useGoogleAnalytics();

  useEffect(() => {
    if (isSuccess && !isLoading) {
      /*ReactGA.event({
        category: "user",
        action: "purchase_created",
        label: userContactId as string,
      });*/
      sendEvent(
        "user",
        "purchase_created",
        userContactId as string,
        createdOrderId,
      );
      toast.success(t("toasts.purchaseCreated"));
      if (!isRequestToShip) navigate("../purchases");
    }

    if (isError) {
      setIsRequestToShip(false);
    }
  }, [isLoading, isSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      toast.success(t("toasts.purchaseUpdated"));
    }

    if (isUpdateError) {
      setIsRequestToShip(false);
    }
  }, [isUpdateProcess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      navigate(`../purchases`);
    }
  }, [isUpdateSuccess, isRequestToShip]);

  useEffect(() => {
    if (isSuccess) {
      navigate(`../purchases`);
    }
  }, [isSuccess, isRequestToShip, createdOrderId]);

  useEffect(() => {
    if (orderId > 0) {
      setIsLoadingPurchase(true);
      getPurchase(orderId)
        .then((response: any) => {
          const commodities: CreateCommodityPortalCommand[] = [];

          if (
            response.data.commodities.length &&
            response.data.commodities[0].containerCommodities
          ) {
            response.data.commodities[0].containerCommodities.forEach(
              (item: CommodityDto) => {
                commodities.push({
                  description: item?.description,
                  note: item?.note,
                  quantity: item?.quantity,
                  unitaryValue: item?.unitaryValue,
                  uuid: item.commodityId,
                  weight: item?.weight,
                  commodityTypeId: item?.commodityType?.commodityTypeId,
                  unit: item?.unit,
                  weightUnit: item?.weightUnit,
                });
              },
            );
          }
          const purchase = {...response.data};
          const existedVendor = purchase?.orderEntities?.find(
            (entity: any) => entity.entityType === EntityTypes.Vendor,
          );

          setVendorEntityId(existedVendor?.orderEntityId ?? 0);

          const existedCarrier: CreateOrderEntityCommandValues =
            purchase?.orderEntities?.find(
              (entity: any) => entity.entityType === EntityTypes.Carrier,
            );

          const vendorValues: CreateOrderEntityCommandValues =
            generateOrderEntity(
              EntityTypes.Vendor,
              existedVendor?.nonContactName === ""
                ? existedVendor?.contactName
                : existedVendor?.nonContactName,
              existedVendor?.orderEntityId,
            );
          if (existedVendor?.customValues?.vendorDate)
            vendorValues.customValues!.vendorDate = dayjs(
              existedVendor.customValues.vendorDate,
            ).format("YYYY-MM-DD");

          const carrierValues: CreateOrderEntityCommandValues =
            generateOrderEntity(
              EntityTypes.Carrier,
              existedCarrier?.nonContactName,
              existedCarrier?.orderEntityId,
            );

          purchase.vendor = vendorValues;
          purchase.carrier = carrierValues;

          setInitialValues({...purchase, commodities});
        })
        .finally(() => {
          setIsLoadingPurchase(false);
        });
    }
  }, [params.purchaseId]);

  const handleWorkflowError = (error: any) => {
    const message = error?.data?.errors?.General?.[0];
    toastError(message);
  };

  const submitHandler = async (
    values: CreateOrderPortalCommand,
    actions: any,
  ) => {
    const convertedCommodities: any =
      values?.commodities?.map((el) => {
        const customValues: CommodityCustomValues = {
          ...el.customValues,
          dangerousItems: Array.isArray(el.customValues?.dangerousItems)
            ? el.customValues?.dangerousItems.join(",")
            : el.customValues?.dangerousItems,
        };
        el.quantity = Number(el.quantity);
        if (el?.unitaryValue) el.unitaryValue = Number(el.unitaryValue);
        return {
          ...el,
          customValues,
        };
      }) ?? [];

    //adding removed commodities with flag
    initialValues.commodities?.forEach((commodity) => {
      if (!convertedCommodities?.find((c: any) => c.uuid == commodity.uuid)) {
        convertedCommodities.push({...commodity, isRemoved: true});
      }
    });

    const orderEntities: CreateOrderEntityCommandValues[] =
      values?.orderEntities ? [...values.orderEntities] : [];

    const vendorIndex: number = orderEntities.findIndex(
      (entity: CreateOrderEntityCommandValues) =>
        entity.orderEntityId === values.vendor!.orderEntityId &&
        entity.entityType === EntityTypes.Vendor,
    );
    if (vendorIndex > -1) {
      const vendor = {...values.vendor};
      if (vendor) {
        vendor.nonContactName = "";
        vendor.orderEntityId = vendorEntityId;
        orderEntities?.splice(vendorIndex, 1, vendor);
      }
    } else if (
      values.vendor?.nonContactName ||
      values.vendor?.customValues?.vendorDate
    ) {
      //replace order entity id with stored value(workaround)
      //order entities and vendor order entity id is lost while submittng
      //TODO: rewrite all this logis
      const vendor = {...values.vendor};
      vendor.orderEntityId = vendorEntityId;
      orderEntities?.push(vendor);
    }

    const carrierIndex: number = orderEntities?.findIndex(
      (entity: CreateOrderEntityCommandValues) =>
        entity.orderEntityId === values.carrier!.orderEntityId &&
        entity.entityType === EntityTypes.Carrier,
    );
    if (carrierIndex > -1) {
      orderEntities?.splice(carrierIndex, 1, values.carrier!);
    } else if (values?.carrier?.nonContactName) {
      orderEntities?.push(values.carrier);
    }

    const purchase: any = {
      ...values,
      orderEntities,
      commodities: convertedCommodities,
    };

    if (isCreatingMode) {
      try {
        const result: any = await onSubmit(purchase);
        const createPurchaseError = result?.error;
        if (createPurchaseError) {
          setIsRequestToShip(false);
          handleWorkflowError(createPurchaseError);
        } else {
          setCreatedOrderId(result?.data?.orderId);
        }
      } catch (error: any) {
        toastError(error);
        setIsRequestToShip(false);
      }
    } else {
      try {
        const result: any = await onUpdate(purchase);
        const updatePurchaseError = result?.error;
        if (updatePurchaseError) {
          setIsRequestToShip(false);
          handleWorkflowError(updatePurchaseError);
        } else {
          setIsRequestToShip(false);
          if (orderId) clearAffectedShipRequestsFromState(orderId);
        }
      } catch (error: any) {
        toastError(error);
      }
    }
  };

  return isLoadingPurchase ? (
    <Grid sx={{display: "flex", justifyContent: "center", mt: 3}}>
      <CircularProgress />
    </Grid>
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={getPurchaseSchema(t)}
      onSubmit={submitHandler}
    >
      {({isSubmitting, submitForm, isValid}) => {
        useEffect(() => {
          setIsRequestToShip(isValid);
        }, [isValid]);

        return (
          <Form>
            <Grid container columns={{xs: 6, md: 12}} height="100%">
              <Grid
                xs={6}
                md={5}
                height="100%"
                display={{xs: !showItemsMobile ? "block" : "none", md: "block"}}
              >
                <Grid
                  container
                  direction={"column"}
                  display={{xs: showItemsMobile && "none"}}
                  columns={{xs: 6, md: 5}}
                  height="100%"
                >
                  <Grid height="100%">
                    <PurchaseDetails
                      handleNext={handleNext}
                      setIsRequestToShip={setIsRequestToShip}
                      submitForm={submitForm}
                    />
                  </Grid>
                  {isDesktop && (
                    <Grid md={4}>
                      <Grid
                        container
                        direction={"row"}
                        sx={{mt: 3, mb: 8}}
                        columns={{xs: 6, md: 4}}
                        columnSpacing={3}
                        rowSpacing={1}
                      >
                        <Grid md={2}>
                          <LoadingButton
                            sx={{p: 1}}
                            fullWidth
                            type="button"
                            variant="contained"
                            color="primary"
                            data-testid="btn-save-purchase"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            loadingPosition="end"
                            onClick={() =>
                              submitForm().then(() => setIsRequestToShip(false))
                            }
                            endIcon={<></>}
                          >
                            <Trans i18nKey="save">Save</Trans>
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid md />
              <Grid
                xs={6}
                md={5}
                // TODO mobile later
                display={{xs: showItemsMobile ? "block" : "none", md: "block"}}
                sx={{marginX: showItemsMobile ? "1rem" : ""}}
                height="100%"
              >
                <PurchaseItemsList submitForm={submitForm} />
                {/* <Box
                  sx={{
                    backgroundColor: "#F5F9FF",
                    border: "1px solid #3E80FF",
                    borderRadius: "16px",
                    padding: "20px",
                    display: {xs: "none", md: "block"},
                    textAlign: "left",
                  }}
                >
                  <Box display="flex" sx={{mb: 4}}>
                    <InfoIcon style={{fill: "#2F81EF"}} />
                    <Typography sx={{ml: 1}} variant="h2">
                      <Trans i18nKey="purchases.editPurchase.shippingInstructionsTitle">
                        Add Purchase info for a speedy delivery!
                      </Trans>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body4">
                      <Trans i18nKey="purchases.editPurchase.shippingInstructions">
                        Click request to ship and your purchase will be shipped
                        to you as soon as it is delivered to our warehouse.
                      </Trans>
                    </Typography>
                  </Box>
                </Box> */}
              </Grid>
              <Grid md />
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}
