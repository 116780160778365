import React, {useEffect, useMemo, useRef, useState} from "react";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {Field, Form, Formik, FormikProps} from "formik";
import {RadioGroup} from "formik-mui";
import {LoadingButton} from "@mui/lab";
import {Trans, useTranslation} from "react-i18next";
import Radio from "@mui/material/Radio";
import {getEditAccountPhoneNumberSchema} from "../pages/validation";
import {
  useVerificationPhoneCodeMutation,
  VerificationPhoneCodeApiArg,
} from "../../../features/verification/verification-api";
import CodeVerificationFragment from "../../components/codeVerificationFragment";
import {useAppSelector} from "../../../store";
import {toast} from "react-hot-toast";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import GppBadIcon from "@mui/icons-material/GppBad";
import MuiPhoneNumber from "material-ui-phone-number";
import {useLazyUserGetInfoQuery} from "../../../features/user/user-api";
import {
  CountryFilter,
  useGetCountries,
} from "../../../common/hooks/useGetCountries";

interface EditPhoneNumberFormValues {
  sendingType: "call" | "sms" | "";
  phoneNumber: string;
}

export default function EditAccountPhoneNumberForm() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const {t} = useTranslation();
  const userState = useAppSelector((state) => state.userState);

  const [sendPhoneCode] = useVerificationPhoneCodeMutation();
  const [getUserInfo] = useLazyUserGetInfoQuery();

  const [initialValues, setInitialValues] = useState<EditPhoneNumberFormValues>(
    {
      phoneNumber: userState?.phoneNumber || "",
      sendingType: "",
    },
  );
  const formRef = useRef<FormikProps<EditPhoneNumberFormValues>>(null);

  const [isSendingCode, setIsSendingCode] = useState<boolean>(false);
  const [codeWasSent, setCodeWasSent] = useState<boolean>(false);

  const {data: phoneNumberCountries, isLoading: isPhoneNumberCountriesLoading} =
    useGetCountries({
      filter: CountryFilter.PhoneNumber,
    });

  const phoneNumberCountryCodes = useMemo(() => {
    return phoneNumberCountries?.map(
      (country) => country?.countryCode?.toLowerCase() ?? "",
    );
  }, [phoneNumberCountries]);

  const CODE_RECEIVE_OPTIONS = [
    {
      label: (
        <Trans i18nKey="profile.editPhoneNumber.phoneCall">Phone call</Trans>
      ),
      value: "call",
    },
    {
      label: (
        <Trans i18nKey="profile.editPhoneNumber.textMessage">
          Text message
        </Trans>
      ),
      value: "sms",
    },
  ];

  useEffect(() => {
    setInitialValues((prevState) => ({
      phoneNumber: userState?.phoneNumber || "",
      sendingType: prevState.sendingType,
    }));
  }, [userState?.phoneNumber, userState?.lastName]);

  const onPhoneNumberChange = (
    event: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formikProps: FormikProps<EditPhoneNumberFormValues>,
  ) => {
    setCodeWasSent(false);
    let newValue: string;

    if (typeof event === "string") {
      newValue = event;
    } else {
      newValue = event.target.value;
    }

    const formattedPhoneNumber = newValue
      .replace(/\(/g, "")
      .replace(/\)/g, "")
      .replace(/-/g, "")
      .replace(/ /g, "");
    formikProps.setFieldValue("phoneNumber", formattedPhoneNumber);
  };

  const onSubmitPhoneNumber = async (
    values: EditPhoneNumberFormValues,
    isResendingCode = false,
  ) => {
    if (isSendingCode) return;

    setIsSendingCode(true);
    try {
      const commandArgs: VerificationPhoneCodeApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        sendPhoneCodeCommand: values,
      };
      const response = await sendPhoneCode(commandArgs);
      if ((response as any)?.error) {
        return;
      }

      if (isResendingCode) {
        toast.success(t("toasts.codeWasResent"));
      } else {
        await getUserInfo();
        setCodeWasSent(true);
        toast.success(t("toasts.phoneNumberWasUpdatedWithCode"));
      }
    } finally {
      setIsSendingCode(false);
    }
  };

  const onResendCode = async () => {
    const currentUserPhoneNumber =
      userState?.phoneNumber || formRef?.current?.values?.phoneNumber;
    if (currentUserPhoneNumber) {
      await onSubmitPhoneNumber(
        {
          phoneNumber: currentUserPhoneNumber,
          sendingType: formRef?.current?.values?.sendingType || "sms",
        },
        true,
      );
    }
  };

  return (
    <Grid
      container
      columns={12}
      display="flex"
      flexDirection="column"
      mt={{
        xs: 4,
        md: "32px",
      }}
    >
      <Grid xs={12}>
        <Typography component="div" variant="body2">
          <Trans i18nKey="profile.editPhoneNumber.verificationOption">
            Verification option
          </Trans>
          {":"}
        </Typography>
      </Grid>
      <Grid mt={1}>
        <Formik
          onSubmit={(values) => onSubmitPhoneNumber(values, false)}
          initialValues={initialValues}
          validationSchema={getEditAccountPhoneNumberSchema(
            t,
            initialValues.phoneNumber,
          )}
          innerRef={formRef}
        >
          {(formikProps: FormikProps<EditPhoneNumberFormValues>) => (
            <Form id="editAccountPhoneNumberForm">
              <Field name="sendingType" component={RadioGroup} row type="text">
                {CODE_RECEIVE_OPTIONS.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    label={option.label}
                    value={option.value}
                    control={<Radio />}
                    name="sendingType"
                    disabled={false}
                    sx={{
                      color:
                        formikProps?.touched?.sendingType &&
                        formikProps?.errors?.sendingType
                          ? "error.main"
                          : "initial",
                      ".MuiRadio-root": {
                        color:
                          formikProps?.touched?.sendingType &&
                          formikProps?.errors?.sendingType
                            ? "error.main"
                            : "",
                      },
                    }}
                  />
                ))}
              </Field>
              {formikProps?.touched?.sendingType &&
              formikProps?.errors?.sendingType ? (
                <Typography
                  component="div"
                  variant="caption3"
                  sx={{
                    color: "error.main",
                  }}
                >
                  {formikProps.errors.sendingType}
                </Typography>
              ) : null}
              <Grid
                xs
                md={3}
                sx={{
                  minWidth: {
                    xs: "0",
                    md: "394px",
                  },
                  marginTop: "26px",
                }}
              >
                {isPhoneNumberCountriesLoading ? (
                  <CircularProgress size={30} />
                ) : (
                  <>
                    <MuiPhoneNumber
                      disableAreaCodes={true}
                      fullWidth
                      size={isDesktop ? "medium" : "small"}
                      id="phoneNumber"
                      label={t("phoneNumber")}
                      name="phoneNumber"
                      autoComplete="phoneNumber"
                      variant="outlined"
                      defaultCountry={"us"}
                      excludeCountries={["ru"]}
                      onlyCountries={phoneNumberCountryCodes}
                      value={formikProps.values.phoneNumber}
                      onChange={(
                        event:
                          | string
                          | React.ChangeEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >,
                      ) => onPhoneNumberChange(event, formikProps)}
                      disabled={isSendingCode}
                      error={Boolean(
                        formikProps.touched.phoneNumber &&
                          formikProps.errors.phoneNumber,
                      )}
                    />
                    {formikProps?.touched?.phoneNumber &&
                    formikProps?.errors?.phoneNumber ? (
                      <Typography
                        component="div"
                        variant="caption3"
                        sx={{
                          color: "error.main",
                          marginLeft: "14px",
                        }}
                      >
                        {formikProps.errors.phoneNumber}
                      </Typography>
                    ) : null}
                  </>
                )}
              </Grid>
              <Grid
                xs
                md={1}
                sx={{
                  minWidth: {
                    xs: 0,
                    md: "185px",
                  },
                  marginTop: "32px",
                }}
              >
                <LoadingButton
                  fullWidth
                  type="submit"
                  form="editAccountPhoneNumberForm"
                  variant="contained"
                  color="secondary"
                  data-testid="btn-get-code-phone"
                  disabled={isSendingCode || codeWasSent}
                  loading={isSendingCode}
                  loadingPosition="end"
                  endIcon={<></>}
                  sx={{
                    textTransform: "none",
                  }}
                >
                  <Trans i18nKey="save">Save</Trans>
                </LoadingButton>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "48px",
          columnGap: "4px",
        }}
      >
        {userState?.phoneNumberConfirmed ? (
          <VerifiedUserIcon sx={{color: "success.main", width: "24px"}} />
        ) : (
          <GppBadIcon sx={{color: "error.main", width: "24px"}} />
        )}
        <Box>
          <Trans i18nKey="profile.editPhoneNumber.phoneNumber">
            Phone number
          </Trans>{" "}
          <Typography
            component="span"
            variant="body3"
            sx={{lineBreak: "anywhere"}}
          >
            {userState?.phoneNumber}
          </Typography>{" "}
          {userState?.phoneNumberConfirmed ? (
            <Trans i18nKey="profile.editPhoneNumber.isVerified">
              is verified
            </Trans>
          ) : (
            <Trans i18nKey="profile.editPhoneNumber.isNotVerified">
              is not verified
            </Trans>
          )}
          .
        </Box>
      </Box>
      {userState?.phoneNumber && !userState?.phoneNumberConfirmed ? (
        <CodeVerificationFragment
          verificationMethod="phone"
          onResendCode={onResendCode}
          containerSx={{marginTop: "24px"}}
        />
      ) : null}
    </Grid>
  );
}
